@import url('variables.css');

.ag-theme-balham {
    .ag-header {
        border-bottom: 0;

        .ag-header-row:nth-child(2) {
            background-color: #F5F5F5;
        }

        .ag-header-row {
            color: var(--ncss-black);
            font-weight: bold;
            font-size: 14px;
        }

        .ag-header-cell::after, .ag-header-group-cell::after {
            border-right: none;
        }
    }

    .ag-row {
        border-color: transparent;

        .ag-cell {
            font-weight: 400;
            font-size: 14px;
            color: #3e3e3e;
            border-color: transparent;
            white-space: normal !important;
            word-break: break-word;
        }
    }

    .ag-row-odd {
        background-color: #F5F5F5;
    }

    .ag-row-selected {
        background: #ccebff;
    }
}

.ag-theme-balham .ag-paging-panel {
    justify-content: space-between;
    margin-right: 16px;
    padding: 0px;
    border: none;
}

.ag-theme-balham .ag-root {
    border: none;
}

.ag-theme-balham .ag-side-bar {
    border: none !important;
}

.ag-theme-balham .ag-status-bar {
    border: none;
}

.alert-icon {
    color: var(--ncss-error);
}

.ag-theme-balham .ag-header-cell-label {
    width: 100%;
    font-weight: 600;
    line-height: 150%;
}

.ag-theme-balham .ag-row {
    border-color: white;
}

.span-width {
    margin-left: 22px;
}

.custom-height {
    overflow: hidden;
    height: 80vh;

    @media screen and (max-width: 2080px) and (min-width: 1439px) {
        height: 60rem;
    }
}

.test-detail-height {
    height: 15rem;
}

.ag-row-no-animation .ag-row {
    transition: none;
}

.ag-header-cell-label {
    text-overflow: clip;
    overflow: visible;
    white-space: normal;
}

.ag-theme-balham .ag-header-cell-label .ag-header-cell-text {
    white-space: normal;
}

font-weight normal {
    text-transform: uppercase;
}

.ag-header-cell-text {
    text-transform: capitalize;
}

.report-grid, .ag-header-cell-text {
    line-height: 12px;
}

.custom-height-lg {
    height: 35rem;
}

.ag-floating-filter-input {
    border: 1px solid #dedede;
}

.ag-theme-balham .ag-header-cell.litegrey {
    background-color: #ffffff;
    color: #808080;
}

.ag-theme-balham .ag-header-cell.black {
    background-color: #111111;
    color: #ffffff;
}

.ag-theme-balham .ag-header-cell.pmdColHeader {
    background-color: #aeaaaa;
    color: #ffffff;
}

.ag-theme-balham .ag-header-cell.capVizColHeader {
    background-color: #5b9ad5;
    color: #ffffff;
}

.ag-theme-balham .ag-header-cell.editableColHeader {
    background-color: #62a57e;
    color: #ffffff;
}

.ag-theme-balham .ag-header-cell.headGreyCloud {
    background-color: #6a6a6a;
    background: rgb(255, 255, 255);
    color: #6A6A6A;
    border-bottom: 5px solid #aeaeae;
}

.ag-theme-balham .ag-header-cell.headLapisBlue {
    background: rgb(255, 255, 255);
    color: #6A6A6A;
    border-bottom: 5px solid #203763;
}

.ag-theme-balham .ag-header-cell.headBlueEyes {
    background: rgb(255, 255, 255);
    color: #6A6A6A;
    border-bottom: 5px solid #0372c3;
}

.ag-theme-balham .ag-header-cell.headSapphireBlue {
    background: rgb(255, 255, 255);
    color: #6A6A6A;
    border-bottom: 5px solid #0372c3;
}

.ag-theme-balham .ag-header-cell.headViolet {
    background: rgb(255, 255, 255);
    color: #6A6A6A;
    border-bottom: 5px solid #248eb7;
}

.ag-theme-balham .ag-header-cell.headSilkBlue {
    background: rgb(255, 255, 255);
    color: #6A6A6A;
    border-bottom: 5px solid #6a87b3;
}

.ag-theme-balham .ag-header-cell.grey {
    background-color: #383838;
    color: #ffffff;
}

.ag-theme-balham .ag-header-cell, .ag-theme-balham .ag-header-group-cell, .ag-theme-balham .ag-cell {
    padding-left: 5px;
    padding-right: 5px;
}

.editableCol {
    background-color: #f1f7ef;
    border-bottom: 1px solid #a2d990 !important;
}

.capVizCol {
    background-color: #d9e1f2;
    border-bottom: 1px solid #bec6d8 !important;
}

.GreyCloud {
    background-color: #f5f5f5;
    border-bottom: 1px solid #dadada !important;
}

.LapisBlue {
    background-color: #ffffff;
    border-bottom: 1px solid #bec6d8 !important;
}

.BlueEyes {
    background-color: #ffffff;
    border-bottom: 1px solid #bec6d8 !important;
}

.Violet {
    background-color: #ffffff;
    border-bottom: 1px solid #d5cfdc !important;
}

.SapphireBlue {
    background-color: #ffffff;
    border-bottom: 1px solid #bec6d8 !important;
}

.SilkBlue {
    background-color: #ffffff;
    border-bottom: 1px solid #bec6d8 !important;
}

.editableGreyColumn {
    background-color: #d4d0d0;
    border-bottom: 1px solid #dadada !important;
}

.editableRedColumn {
    background-color: #f9baba;
    border-bottom: 1px solid #dadada !important;
}

.editableYellowColumn {
    background-color: #ffffd3;
    border-bottom: 1px solid #fad000 !important;
}

.numberEditabeCol {
    background-color: #ebf3df;
    border-bottom: 1px solid #c5cabe !important;
}

.dateEditabeCol {
    background-color: #ebf3df;
    border-bottom: 1px solid #c5cabe !important;
}

.gridDropDown {
    background-color: #f9f9f9;
    display: block;
    border: 1px solid #d3d3d3;
    padding: 2px 9px;
    line-height: 24px;
    border-radius: 20px;
}

.gridDropDown i {
    float: right;
    margin-top: 8px;
}

.smallWidthColumn {
    width: 60px;
}

.ag-rich-select {
    width: auto !important;
    min-width: 390px;
}

.ag-theme-balham .ag-icon-none {
    background-position: center 5px;
}

.custom-date-filter a {
    position: relative;
    right: 30px;
    color: rgba(0, 0, 0, 0.54);
    cursor: pointer;
}

.custom-date-filter:after {
    content: '\f073';
    display: block;
    font-weight: 400;
    font-family: 'Font Awesome 5 Free';
    position: absolute;
    right: 13px;
    pointer-events: none;
    color: rgba(0, 0, 0, 0.54);
    top: 9px;
}

.ag-tool-panel-wrapper {
    box-shadow: 0px 0px 15px #393939;
}

.ag-header-icon.ag-filter-icon {
    position: absolute;
    right: 0;
    top: 9px;
}

.ag-header-icon.ag-sort-order {
    display: none;
}

.ag-theme-balham .ag-icon-none {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA3klEQVQ4jbWQuwqDMBiFTy+bg1tpFik2+AAhk4i4OvRtfLCOtdqH6GVu6SBBGxSfIF0UglhbhR7IEH6+L+cP8M8wxk6MsXQSbNv2ra5rVVWVopReRsNSStUmz/PfJV14jGTuum7aB+sS3/ePAGYttNQElmma6yiKiqFXDMMgACwAT+imJgTA5suWdwB5XwMAmDdnKAv9ojfYhmG4J4Sshugsy15xHO8APLqzmed5SVEUHz9RCKGCIDgM9qOUXvskQgjlOM75y3r9klGwLinLUkkpx8NtOOcp5zyZBP+aNxm2xJOkSl8xAAAAAElFTkSuQmCC');
    background-position: -3px 7px;
}

.ag-theme-balham .ag-icon-filter {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAABNElEQVQ4jcWRPUvDUBSG3xulpJNC9gz6E8TBgP4TF6ekgRK6ujk5tUkKdu0vaMjukGhxcc2gdKtwoWRIU8gthuTGKVLTD5upL9zlvOd5OHCBQ4cAQK/XO+WcX1RLQRByzvnRGkRI2ul0Xn8Ftm0/iqKoNZtNvrrYaDSyNE2Pq4I4jossy64Mw/gk5XAwGLwpinIpy/IasBrP8+aU0ntd158AQCgLTdMU3/cXSZJshYMgYLPZzCnhPwIAYIydjUaj700wpTQPguBDVdW7XRfCNM2b4XAYRVFUlI9SWliWtdi0L1QHhmG8LJfLh/F4PC9njuMwQsj5XgIAkCTJnkwmJwAwnU5BCHlvt9vh3oI6+VcQhmEO4Gtbv/PPXdeNGWPPrVbrttZZRVGQfr/vdLvd61rgQfIDukuHiEdHzukAAAAASUVORK5CYII=');
}

.ag-tool-panel-wrapper {
    box-shadow: 0px 0px 15px #393939;
}

.ag-header-icon.ag-filter-icon {
    position: absolute;
    right: 0;
    top: 9px;
}

.ag-header-icon.ag-sort-order {
    display: none;
}

.ag-theme-balham .ag-icon-none {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA3klEQVQ4jbWQuwqDMBiFTy+bg1tpFik2+AAhk4i4OvRtfLCOtdqH6GVu6SBBGxSfIF0UglhbhR7IEH6+L+cP8M8wxk6MsXQSbNv2ra5rVVWVopReRsNSStUmz/PfJV14jGTuum7aB+sS3/ePAGYttNQElmma6yiKiqFXDMMgACwAT+imJgTA5suWdwB5XwMAmDdnKAv9ojfYhmG4J4Sshugsy15xHO8APLqzmed5SVEUHz9RCKGCIDgM9qOUXvskQgjlOM75y3r9klGwLinLUkkpx8NtOOcp5zyZBP+aNxm2xJOkSl8xAAAAAElFTkSuQmCC');
    background-position: -3px 7px;
}

.ag-theme-balham .ag-icon-filter {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAABNElEQVQ4jcWRPUvDUBSG3xulpJNC9gz6E8TBgP4TF6ekgRK6ujk5tUkKdu0vaMjukGhxcc2gdKtwoWRIU8gthuTGKVLTD5upL9zlvOd5OHCBQ4cAQK/XO+WcX1RLQRByzvnRGkRI2ul0Xn8Ftm0/iqKoNZtNvrrYaDSyNE2Pq4I4jossy64Mw/gk5XAwGLwpinIpy/IasBrP8+aU0ntd158AQCgLTdMU3/cXSZJshYMgYLPZzCnhPwIAYIydjUaj700wpTQPguBDVdW7XRfCNM2b4XAYRVFUlI9SWliWtdi0L1QHhmG8LJfLh/F4PC9njuMwQsj5XgIAkCTJnkwmJwAwnU5BCHlvt9vh3oI6+VcQhmEO4Gtbv/PPXdeNGWPPrVbrttZZRVGQfr/vdLvd61rgQfIDukuHiEdHzukAAAAASUVORK5CYII=');
}

.customEditableColumn {
    word-wrap: break;
}

.reasonCodeACTIVE {
    font-size: 12px;
    color: #297900;
    font-weight: 500;
}

.reasonCodeINACTIVE {
    font-size: 12px;
    color: #ff0000;
    font-weight: 500;
}

.ag-theme-balham .ag-row .ag-cell.smallGridText {
    font-size: 12px;
    line-height: 18px;
}

.peachOrange {
    background-color: #ffe8d2;
    border-bottom: 1px solid #ffa97d !important;
}

.ag-virtual-list-container {
    min-width: 300px;
    overflow: visible;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
}

.ag-virtual-list-item {
    padding-right: 10px;
    width: max-content;
}

.cellStatusBox {
    background-color: rgba(117, 117, 117, 0.3);
    padding: 4px 10px;
    display: block;
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    border-radius: 4px;
    margin-top: 4px;
    line-height: 14px;
    letter-spacing: 0.2px;
    width: 100%;
}

.cellStatusBox.di {
    display: inline !important;
}

.bg-grey-lite {
    background-color: rgba(117, 117, 117, 0.3);
}

.bg-amber-lite {
    background-color: rgba(250, 84, 0, 0.3);
}

.bg-success-lite {
    background-color: rgba(18, 138, 9, 0.3);
}

.bg-grey1-lite {
    background-color: #e5e5e5;
}

.cellLineBox {
    padding: 2px;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.2px;
    display: block;
    color: #111111;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    min-height: 50px;
}

.cellBreakText {
    word-wrap: break-word;
}

.text-color-secondary {
    color: #757575;
}

.hide-horizontal-scroll .ag-body-horizontal-scroll {
    visibility: hidden;
}

.ag-react-container {
    width: inherit;
}

.ag-theme-balham {
    font-family: HelveticaNeue, 'Nunito Sans', -apple-system, '.SFNSText-Regular', 'San Francisco', BlinkMacSystemFont, 'Inter', 'Segoe UI', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
}

.ag-theme-balham .ag-cell.ag-cell-inline-editing input, .ag-theme-balham .ag-cell.ag-cell-inline-editing select, .ag-theme-balham .ag-cell.ag-cell-inline-editing textarea {
    min-height: 30px !important;
    box-shadow: inset 0px 0px 2px rgb(97, 97, 97);
    padding: 3px !important;
}

.actionDelete:not(:first-child) > span > span > i {
    color: #FF0000;
}

.actionAdd:not(:first-child) > span > span > i {
    color: #1C7013;
}

.actionModify:not(:first-child) > span > span > i {
    color: #FBBE3B;
}

.actionAddText {
    color: #1C7013 !important;
}

.actionDeleteText {
    color: #111111 !important;
    text-decoration: line-through !important;
}

.gridlistItems {
    padding: 0 0 0 30px;
    list-style: number;
}

.gridlistItems li {
    padding: 0 0 0 5px;
}