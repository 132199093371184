.g-b-d-menu {
    font-family: -apple-system, BlinkMacSystemFont, 'Inter', Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
}

.g-b-d-menu .u-bold {
    font-weight: 500;
}

.g-b-d-menu .seperation-header {
    background-color: transparent;
    margin: 10px 0 0 0;
    padding: 11px 10px 7px 10px;
    border-top: 1px solid #dedede;
    font-size: 13px;
}

.g-b-d-menu li.text-color-grey:first-child .seperation-header {
    margin: 0;
    border: none;
}

.g-b-d-menu .fs18-sm {
    font-size: 14px;
    line-height: 2;
}

.g-b-d .GreyCloud {
    background-color: #f7f7f7;
    border-bottom: 1px solid #dadada !important;
}

.g-b-d .ag-cell-label-container {
    border-right: none !important;
}

.g-b-d .ag-theme-balham .ag-header-cell-label .ag-header-cell-text {
    white-space: normal;
    font-weight: 600;
    font-size: 12px;
}

.g-b-d .ag-header-group-text {
    font-weight: 600;
}

.g-b-d .ag-theme-balham .ag-header-cell:not(.ag-header-group-cell-no-group) {
    border-top-width: 1px;
    border-right-width: 1px;
    border-right-color: #00000026;
}

.g-b-d .ag-theme-balham .ag-header-group-cell:not(.ag-header-group-cell-no-group) {
    border-left: 1px solid rgba(0, 0, 0, 0.149);
    border-bottom: 1px solid rgba(0, 0, 0, 0.149);
    border-top: none;
}

.g-b-d .ag-theme-balham .ag-ltr .ag-cell.ag-cell-focus {
    border-bottom: 1px solid #d7d7d7;
    border-right: 1px solid rgba(64, 64, 64, 0.18);
}

.g-b-d .ag-theme-balham .ag-ltr .ag-cell {
    border-bottom: 1px solid #d7d7d7;
    border-right: 1px solid #4040402e;
}

.ag-theme-balham .ag-header-cell, .ag-theme-balham .ag-header-group-cell {
    border-style: solid;
    border-color: #0000003b;
    padding-left: 12px;
    padding-right: 12px;
    border-width: 0;
}

.ag-theme-balham .ag-icon-contracted:after, .ag-theme-balham .ag-icon-contracted:before {
    color: #000000;
}

.ag-header-group-cell.gdGridHeadPinned {
    background-color: #b5b5b5;
}

.ag-header-group-cell.gdGridHeadDetails {
    background-color: #a19c8f;
}

.ag-header-group-cell.gdGridHeadCapacity {
    background-color: #9094a2;
}

.ag-header-group-cell.gdGridHeadQOQ {
    background-color: #9a90a2;
}

.ag-header-cell.gdGridHeadLabelPinned {
    background-color: #cbcbcb;
}

.ag-header-cell.gdGridHeadLabelDetails {
    background-color: #c9c3b3;
}

.ag-header-cell.gdGridHeadLabelCapacity {
    background-color: #abafbf;
}

.ag-header-group-cell.gdGridHeadGb {
    background-color: #506155;
}

.ag-header-group-cell.gdGridCatGbDOdd {
    background-color: #5e7163;
}

.ag-header-group-cell.gdGridCatGbEven {
    background-color: #6b8172;
}

.ag-header-group-cell.gdGridDimGbOdd {
    background-color: #799180;
}

.ag-header-group-cell.gdGridDimGbEven {
    background-color: #86a18e;
}

.ag-header-group-cell.gdGridLevGbOdd {
    background-color: #86a18e;
}

.ag-header-group-cell.gdGridLevGbEven {
    background-color: #92aa99;
}

.ag-header-cell.gdGridRcGbOdd {
    background-color: #9eb4a5;
}

.gdGridRcGbEven {
    background-color: #aabdb0;
}

.ag-header-group-cell.gdGridHeadQoq {
    background-color: #7f6a5f;
}

.ag-header-group-cell.gdGridCatQoqDOdd {
    background-color: #91796c;
}

.ag-header-group-cell.gdGridCatQoqEven {
    background-color: #a3887a;
}

.ag-header-group-cell.gdGridDimQoqOdd {
    background-color: #b59787;
}

.ag-header-group-cell.gdGridDimQoqEven {
    background-color: #b59787;
}

.ag-header-group-cell.gdGridLevQoqOdd {
    background-color: #bca193;
}

.ag-header-group-cell.gdGridLevQoqEven {
    background-color: #c4ac9f;
}

.ag-header-cell.gdGridRcQoqOdd {
    background-color: #cbb6ab;
}

.ag-header-cell.gdGridRcQoqEven {
    background-color: #d3c1b7;
}

.lever-icon {
    width: 24px;
    height: 24px;
    display: inline-block;
}

.lever-icon-qc {
    background: no-repeat center center url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAXlJREFUSEu9lT0vRUEQhp9bKdQKiYJGBJFIfERBaFChuX6BXnT+gc6PUIgo9CoSifiISqgkFCp0Ogry3sy5mezdPdc5517TnJPszjzvzs7M1uiy1bocHw84A3qALeCxU2APuATmgAdg848QiZItpQR5wABwCMwXgPxY4GSqYwvHQN0gG8CTBRkEpFjfmF0D24C+TQsBe8CuW1e6VoFXC5wHyNzGfHpDwLMF2gEWgXU7if4/IrJ9irRHAk5NVGN7CPAOvcAJsGwQFcBnAAkvueVO8gCK1Q8cAQsGmQS+c0q4MECxRoADYNog450A5PWZLt5DYtXVzEwqRe0a2UNKAVKNMwqoT1SK4UkkqtQdhKfJg9wAfcBQ5tSuilKpSkGGgS/gpSpA/h5yD0zE1JQ9QRbLQ26BmRASAq6AWRu/5+1KydY95MKaMjnsFFyQKiZha9lYiZWjFO0DKwUp78CblfAdMBUbdgVjtmyXOI0VDcXGK/evj35V9VH/X2wfURmMbUqyAAAAAElFTkSuQmCC');
}

.rangeArea {
    background-color: #ccc;
    height: 4px !important;
}

.rangeSteps {
    margin-top: 6px !important;
}