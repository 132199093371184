@import url('variables.css');

.header-container {
    padding: 5px;
    margin: 0;
    position: fixed;
    z-index: 11;
    width: 100%;
    background: rgba(250, 250, 250, 0.9);
    border-bottom: 1px solid #CCCCCC;
    box-shadow: none !important;
    min-height: 64px;
}

.hide-header {
    z-index: 0;
}

.inline-display {
    display: inline-block;
}

.relative-position {
    position: relative;
}

.header-setting-button {
    font-size: 20px;
    background-color: var(--ncss-white);
}

.header-brand {
    font-size: 16px;
    vertical-align: middle;
    display: block;
    padding: 12px 0 0 64px;
    line-height: 24px;
}

.sidebar .header-brand {
    font-size: 16px;
    vertical-align: middle;
    display: block;
    padding: 12px 0 0 15px;
}

.header-name-padding {
    padding: 7px;
}

.menu-icons {
    width: 7%;
}

.label-pos {
    position: absolute;
}

.top-align {
    float: right;
    padding-right: 48px;

    @media screen and (max-width: 1024px) {
        padding-right: 2rem;
    }
}

.header-name {
    float: right;
}

.header-user-name {
    line-height: 15px;
    padding: 1px 15px 3px 0;

    @media screen and (max-width: 600px) {
        display: none;
    }
}

.header-container .ncss-row.headerContent {
    min-height: 54px;
}

.header-container .envHighlight {
    text-align: center;
    background-color: #2f716a;
    font-weight: 600;
    color: #fff;
    letter-spacing: 2px;
    background: #a5a5a5;
    width: 100vw;
    display: block;
    border-top: 4px solid #fa5400;
    top: 0;
    position: absolute;
    height: 2px;
}

.header-container .envHighlight > .nc {
    background-color: #fa5400;
    display: block;
    width: 319px;
    height: 21px;
    border-radius: 0 0 25px 25px;
    margin: 0 auto;
    font-size: 12px;
    line-height: 17px;
    box-shadow: inset 0px -2px 0px #d94d07;
    position: relative;
}

.header-container .envHighlight > .nc::before, .header-container .envHighlight > .nc::after {
    position: absolute;
    content: '';
    display: block;
    height: 12px;
    width: 20px;
    background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' xmlns='http://www.w3.org/2000/svg'%3E%3Ctitle/%3E%3Cg%3E%3Ctitle%3ELayer 1%3C/title%3E%3Cpath id='svg_1' stroke-linecap='square' fill='%23fa5400' d='m0,20l0,-20l20,0l20.001,0l0,40l-20.001,0l-20,0l0,-20zm20,-20c-3.62,0 -6.968,0.892 -10.04,2.676c-3.072,1.784 -5.5,4.212 -7.284,7.284c-1.784,3.072 -2.676,6.42 -2.676,10.04c0,3.62 0.892,6.968 2.676,10.04c1.784,3.072 4.212,5.5 7.284,7.284c3.072,1.784 6.42,2.676 10.04,2.676c3.62,0 6.968,-0.892 10.04,-2.676c3.072,-1.784 5.5,-4.212 7.284,-7.284c1.784,-3.072 2.676,-6.42 2.676,-10.04c0,-3.62 -0.892,-6.968 -2.676,-10.04c-1.784,-3.072 -4.212,-5.5 -7.284,-7.284c-3.072,-1.784 -6.42,-2.676 -10.04,-2.676z'/%3E%3C/g%3E%3C/svg%3E");
}

.header-container .envHighlight > .nc::before {
    top: 0;
    left: -16px;
    background-position: top right;
}

.header-container .envHighlight > .nc::after {
    top: 0;
    right: -16px;
    background-position: top left;
}

.header-container .envHighlight {
    animation: blinkingText 3s infinite;
    transition-timing-function: ease;
}

@keyframes blinkingText {
    0% {
        color: #fff;
    }

    50% {
        color: transparent;
    }

    100% {
        color: #fff;
    }
}

.app-container {
    flex: 1 0 auto;
    min-height: calc(100vh - 144px);
    margin: 64px 0 0px 0;

    @media screen and (max-width: 990px) {
        margin: 64px 0px 60px 0px;
    }

    @media screen and (max-width: 640px) {
        margin: 64px 0px 0px 0px;
    }
}

.app-container.page-searchBar-space {
    min-height: calc(100vh - 165px);
    margin: 115px 0 0px 0;
}

.search-new-container {
    min-height: 50px;
    padding: 8px 5px 5px 10px;
    border-bottom: 1px solid #e9e9e9;
    background: rgba(250, 250, 250, 0.9);
}

.search-new-container.topHeader {
    position: fixed;
    z-index: 2;
    width: 100%;
    top: 65px;
    padding: 5px;
}