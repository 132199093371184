@import url('variables.css');

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  to {
    -webkit-transform: rotate(360deg);
  }
}

.spinner:before {
  content: 'Loading…';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1rem;
  height: 1rem;
  margin-top: -(1rem / 2);
  margin-left: -(1rem / 2);
}

.spinner.spinner-lg:before {
  width: 10rem;
  height: 10rem;
  margin-top: -(10rem / 2);
  margin-left: -(10rem / 2);
}

.spinner:not(:required):before {
  content: '';
  border-radius: 50%;
  border-top: 2px solid #03ade0;
  border-right: 2px solid transparent;
  animation: spinner 0.6s linear infinite;
  -webkit-animation: spinner 0.6s linear infinite;
}

.spinLarge {
  width: 150px;
  margin: 0 auto;
  display: block;
  height: 150px;
}

.spinRelative {
  position: relative;
}

.spinFixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 9999;
}

.spinOverlay {
  position: fixed;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  top: 0;
}