
@property --font-face {
    font-family: 'Helvetica Neue';
    font-weight: 400;
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

.c-f, .c-f-model, .c-f-all *, .c-f-model * {
    font-family: HelveticaNeue, 'Nunito Sans', -apple-system, '.SFNSText-Regular', 'San Francisco', BlinkMacSystemFont, 'Inter', 'Segoe UI', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
}

input, textarea {
    font-family: HelveticaNeue, 'Nunito Sans', -apple-system, '.SFNSText-Regular', 'San Francisco', BlinkMacSystemFont, 'Inter', 'Segoe UI', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.c-f-all * .fa, .c-f-all * .far, .c-f-all * .fas {
    font-family: 'Font Awesome 5 Free';
}

.position-relative {
    position: relative;
}

.u-clearfix {
    clear: both;
}

.c-f-all .ag-header-cell-text {
    text-transform: capitalize;
    line-height: 18px;
}

.c-f-all .ag-theme-balham .ag-header-cell-label {
    font-weight: 500;
}

.c-f-500 {
    font-weight: bold;
    font-weight: 500;
}

.c-f-all .ag-theme-balham .ag-header .ag-header-row {
    background-color: #efefef;
}

.c-f-all .ag-theme-balham .ag-header .ag-header-row:nth-child(1) {
    background-color: #efefef;
    color: #6a6a6a;
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(247, 247, 247, 1) 90%, rgba(239, 239, 239, 1) 100%);
}

.c-f-all .ag-theme-balham .ag-header .ag-header-row:nth-child(2) {
    background-color: #efefef;
    background: #efefef;
}

.c-f-all .ag-theme-balham .ag-row .ag-cell {
    display: flex;
    align-items: center;
    line-height: 120%;
    color: #111; /* justify-content: center; */
}

.log.c-f-all .ag-theme-balham .ag-row .ag-cell {
    padding: 5px;
}

.c-f-all .ag-theme-balham .ag-ltr .ag-cell {
    border-bottom: 1px solid #ccc;
}

.c-f-all .ag-theme-balham .ag-row-odd {
    background-color: #ffffff;
}

.c-f-all .ag-theme-balham .ag-icon-filter {
    background: no-repeat center center url("data:image/svg+xml,%3Csvg width='11' height='10' viewBox='0 0 11 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.146271 1.00625C1.5426 2.625 4.12096 5.625 4.12096 5.625V9.375C4.12096 9.71875 4.43202 10 4.81221 10H6.19471C6.57489 10 6.88596 9.71875 6.88596 9.375V5.625C6.88596 5.625 9.45741 2.625 10.8537 1.00625C11.2063 0.59375 10.8814 0 10.3076 0H0.692358C0.118621 0 -0.206266 0.59375 0.146271 1.00625Z' fill='%23979797'/%3E%3C/svg%3E%0A");
}

.c-f-all .ag-theme-balham .ag-input-wrapper input:not([type]), .c-f-all .ag-theme-balham .ag-input-wrapper input[type='text'], .c-f-all .ag-theme-balham .ag-input-wrapper input[type='number'], .c-f-all .ag-theme-balham .ag-input-wrapper input[type='tel'], .c-f-all .ag-theme-balham .ag-input-wrapper input[type='date'], .c-f-all .ag-theme-balham .ag-input-wrapper input[type='datetime-local'] {
    border-color: #cccccc;
}

.ag-theme-balham .ag-row-selected {
    background-color: #fff !important;
}

.ag-theme-balham .ag-cell-range-selected-1:not(.ag-cell-focus) {
    background-color: #fff !important;
}

.c-f-all .ag-theme-balham .small {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 12px;
    line-height: 1;
}

.c-f-all .ag-theme-balham .ag-header {
    background-color: #e5e5e5;
}

.c-f-all .ag-theme-balham .ag-row-hover {
    background-color: #fff;
}

.c-f-model {
    border: 0px !important;
    padding: 2rem;
}

.ag-theme-balham .ag-ltr .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected), .ag-theme-balham .ag-ltr .ag-has-focus .ag-cell-focus.ag-cell-range-single-cell, .ag-theme-balham .ag-ltr .ag-cell-focus.ag-cell-range-single-cell.ag-cell-range-handle, .ag-theme-balham .ag-rtl .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected), .ag-theme-balham .ag-rtl .ag-has-focus .ag-cell-focus.ag-cell-range-single-cell, .ag-theme-balham .ag-rtl .ag-cell-focus.ag-cell-range-single-cell.ag-cell-range-handle {
    border-top-color: #fff;
    border-left-color: #fff;
    border-right-color: #fff;
    border-bottom-color: #ccc;
}

.ag-theme-balham .ag-cell-wrapper .ag-icon-checkbox-unchecked:before {
    content: '';
    content: '';
    position: absolute;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    border: 1px solid #ddd;
    width: 16px;
    height: 16px;
    border-radius: 2px;
}

.ag-theme-balham .ag-cell-wrapper .ag-icon-checkbox-checked:before {
    content: '';
    content: '';
    position: absolute;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    border: 1px solid #ddd;
    width: 16px;
    height: 16px;
    border-radius: 2px;
}

.ag-theme-balham .ag-cell-wrapper .ag-icon-checkbox-checked:after {
    font-family: nike-glyphs, sans-serif;
    content: '\E611';
    position: absolute;
    top: 50%;
    left: 5px;
    color: #000;
    font-size: 8px;
    line-height: 3;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.c-f-border-5 {
    border-radius: 5px;
}

.c-f-all input.cInput, .c-f-all select.cInput {
    border: 1px #ccc solid;
    border-radius: 4px;
    background: #fff;
    padding: 8px 12px;
    font-family: HelveticaNeue, Arial, sans-serif;
    color: #757575;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    box-shadow: none;
    min-width: 150px;
    margin-right: 20px;
    position: relative;
}

.c-f-all input.cInput:disabled, .c-f-all select.cInput:disabled {
    background-color: #fff !important;
    opacity: 0.4;
}

.c-f-all .fa-caret-up:before, .c-f-all .fa-caret-down:before {
    content: '';
}

.c-f-all .fa-caret-down {
    width: 8px;
    height: 8px;
    background: no-repeat center center url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.64734 4.35266C7.84211 4.15789 7.84211 3.84211 7.64734 3.64734C7.4527 3.4527 7.13716 3.45256 6.94234 3.64703L4.5 6.085L4.5 0.499999C4.5 0.223857 4.27614 0 4 0C3.72386 0 3.5 0.223858 3.5 0.5L3.5 6.085L1.05766 3.64703C0.862839 3.45256 0.547302 3.4527 0.352658 3.64734C0.15789 3.84211 0.15789 4.15789 0.352658 4.35266L4 8L7.64734 4.35266Z' fill='black' fill-opacity='0.7'/%3E%3C/svg%3E%0A");
}

.c-f-all .fa-caret-up {
    width: 8px;
    height: 8px;
    background: no-repeat center center url("data:image/svg+xml,%3Csvg width='8' height='8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath transform='rotate(-180 4,3.9999999999999996) ' id='svg_1' fill-opacity='0.7' fill='black' d='m7.64734,4.35266c0.19477,-0.19477 0.19477,-0.51055 0,-0.70532c-0.19464,-0.19464 -0.51018,-0.19478 -0.705,-0.00031l-2.44234,2.43797l0,-5.585c0,-0.27614 -0.22386,-0.5 -0.5,-0.5c-0.27614,0 -0.5,0.22386 -0.5,0.5l0,5.585l-2.44234,-2.43797c-0.19482,-0.19447 -0.51036,-0.19433 -0.705,0.00031c-0.19477,0.19477 -0.19477,0.51055 0,0.70532l3.64734,3.64734l3.64734,-3.64734z' clip-rule='evenodd' fill-rule='evenodd'/%3E%3C/svg%3E"); 
}

.c-f-all .ag-theme-balham .ag-icon-checkbox-checked {
    color: #7F8C8D;
}

.fs-30px {
    font-size: 30px;
}

.fs-24px {
    font-size: 24px;
}

.fs-18px {
    font-size: 18px;
}

.fs-16px {
    font-size: 16px;
}

.fs-12px {
    font-size: 12px;
}

.fs-14px {
    font-size: 14px;
}

.fs-wb {
    word-break: break-all;
}

.fs-wr {
    word-wrap: break-word;
}

.f-ls-05 {
    letter-spacing: 0.5px;
}

.f-ls-1 {
    letter-spacing: 1px;
}

.f-ls-2 {
    letter-spacing: 2px;
}

.ml-2-sm {
    margin-left: -2px;
}

.search-header-new {
    min-height: 83px;
    padding: 0;
}

.newSearchCol {
    float: left;
    display: inline-block;
    vertical-align: top;
    padding: 2px 10px 2px 0;
    position: relative;
    height: 42px;
    min-width: 26px;
}

.newSearchCol-2 {
    padding-top: 11px;
    padding-left: 19px;
}

.userUpdatesFlag {
    padding-left: 10px;
    padding-top: 21px;
    border-left: 5px solid #4CA5D2;
    height: 54px;
    margin-left: -5px;
    display: block;
    width: 30px;
}

.userUpdatesFlag.read {
    border-left: 5px solid #f1f1f1;
}

.userUpdatesFlag.noPending span {
    opacity: 0;
}

.searchNew .rbt-input-hint-container input, .searchNew .rbt-input-multi {
    width: 100%;
    border: 1px #cccccc solid;
    border-radius: 4px;
    background: #ffffff;
    padding: 4px 7px 3px;
    cursor: pointer;
    font-family: HelveticaNeue, Arial, sans-serif;
    font-weight: 400;
    font-size: 12px;
    text-transform: capitalize;
    color: #757575;
    user-select: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    box-shadow: none;
    min-width: 150px;
    margin-right: 20px;
    position: relative;
}

.searchNew .rbt-menu {
    font-family: HelveticaNeue, Arial, sans-serif;
}

.searchNew .rbt-input-hint-container .rbt-input {
    background-repeat: no-repeat;
    background-position: 97% 50%;
    background-size: 12px 8px;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' preserveAspectRatio='xMidYMid meet' fill='currentColor' style=''%3E%3Cpolygon points='18.5454545 4 20 5.41414141 9.97979798 15.3939394 0 5.41414141 1.41414141 4 9.97979798 12.5656566'%3E%3C/polygon%3E%3C/svg%3E");
    background-image: url("data:image/svg+xml,%3Csvg width='15' height='10' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='xMidYMid meet'%3E%3Cg%3E%3Ctitle%3ELayer 1%3C/title%3E%3Cpolygon stroke='null' id='svg_1' points='12.929924726486206,1.3800511360168457 13.854166746139526,2.2786192893981934 7.487164735794067,8.619948387145996 1.1458334922790527,2.2786192893981934 2.0444023609161377,1.3800511360168457 7.487164735794067,6.822813034057617 '/%3E%3C/g%3E%3C/svg%3E");
}

.searchNew .rbt-input-hint-container {
    position: relative;
}

.searchNew .DayPicker-Border input {
    cursor: pointer;
}

.searchNew .DayPickerInput input {
    min-width: 180px;
    height: 29px;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: 97% 50%;
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='xMidYMid meet'%3E%3Cg%3E%3Ctitle%3Ebackground%3C/title%3E%3C/g%3E%3Cg%3E%3Ctitle%3ELayer 1%3C/title%3E%3Cpath stroke='null' id='svg_1' d='m12.55,9.575l-1.7,0l0,-1.7l1.7,0l0,1.7zm3.4,2.55l-1.7,0l0,-1.7l1.7,0l0,1.7zm0,2.55l-1.7,0l0,-1.7l1.7,0l0,1.7zm0,-5.1l-1.7,0l0,-1.7l1.7,0l0,1.7zm-10.20001,5.1l-1.7,0l0,-1.7l1.7,0l0,1.7zm12.75001,-11.05001l0,13.60001l-17.00001,0l0,-13.60001l2.55,0l0,-0.85l2.55,0l0,0.85l6.8,0l0,-0.85l2.55,0l0,0.85l2.55,0zm-0.85,2.55l-15.30001,0l0,10.20001l15.30001,0l0,-10.20001zm-11.90001,3.4l-1.7,0l0,-1.7l1.7,0l0,1.7zm3.4,5.1l-1.7,0l0,-1.7l1.7,0l0,1.7zm-3.4,-2.55l-1.7,0l0,-1.7l1.7,0l0,1.7zm6.8,2.55l-1.7,0l0,-1.7l1.7,0l0,1.7zm0,-2.55l-1.7,0l0,-1.7l1.7,0l0,1.7zm-3.4,-2.55l-1.7,0l0,-1.7l1.7,0l0,1.7zm0,2.55l-1.7,0l0,-1.7l1.7,0l0,1.7z'/%3E%3C/g%3E%3C/svg%3E");
}

.searchNew .rbt-input-hint-container input.focus, .searchNew .rbt-input-multi.focus {
    border: 1px #000 solid;
}

.drop-down:hover .drop-down:not(.buttonError) {
    border: 1px #111111 solid;
    border-radius: 4px;
    border: var(--create-dropdown-subtle, 1px #111111 solid);
}

.searchNew .rbt-menu>li a {
    display: block;
    width: 100%;
    font-size: 12px;
    text-transform: capitalize;
    padding: 5px 40px 5px 12px;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: inherit;
}

.searchNew .rbt-menu>li a:hover {
    background-color: rgba(229, 229, 229, 0.5);
}

.searchNew .input-text {
    outline: none;
    border: 1px #cccccc solid;
    border-radius: 4px;
    min-width: 180px;
    min-height: 29px;
    line-height: 2.3;
    padding-left: 10px;
    position: absolute;
    color: #111111;
    width: 100%;
    font-size: 12px;
    position: relative;
    padding-top: 8px;
}

.position-relative {
    position: relative;
}

.floatHead {
    position: absolute;
    display: block;
    background-color: #fff;
    margin-top: -6px;
    z-index: 2;
    font-size: 10px;
    font-weight: 400;
    margin-left: 7px;
    padding: 0px 5px;
}

.floatHead-Disable {
    position: absolute;
    display: block;
    background-color: rgba(239, 239, 239, 1) !important;
    margin-top: -6px;
    z-index: 2;
    font-size: 10px;
    font-weight: 400;
    margin-left: 7px;
    padding: 0px 5px;
}

.select-drop-down {
    min-width: 170px;
    font-weight: normal;
    height: 37px;
    font-size: 13px;
}

.css-1okebmr-indicatorSeparator {
    background-color: none;
}

.react-select__control {
    min-height: 29px !important;
    height: 29px !important;
    border-color: #bbb !important;
    box-shadow: none !important;
}

.react-select__control:hover {
    border-color: #000 !important;
    box-shadow: none !important;
}

.float.selected .react-select__control, .float .react-select__control:hover {
    border-color: #000 !important;
    box-shadow: none !important;
}

.react-select__menu {
    margin-top: 1px !important;
}

.react-select__option {
    padding: 4px 12px !important;
    font-size: 12px !important;
    background-color: #fff !important;
    color: #000 !important;
}

.react-select__option--is-focused {
    background-color: rgba(229, 229, 229, 0.5) !important;
}

.react-select__indicator-separator {
    background-color: #fff !important;
}

.react-select__dropdown-indicator {
    padding: 5px !important;
}

.hover-underline:hover {
    text-decoration: underline;
}

.opacity-50 {
    opacity: 0.5;
}

/* overrides with a custom class */
.customTip .react-tooltip-lite {
    border: 1px solid #ccc;
    background: #fff;
    color: #777;
    border-radius: 5px;
    padding: 5px !important;
    font-size: 90%;
    text-align: center;
}

.customTip .react-tooltip-lite-arrow {
    border-color: #ccc;
    position: relative;
}

.customTip .react-tooltip-lite-arrow::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    z-index: 99;
    display: block;
}

.customTip .react-tooltip-lite-up-arrow::before {
    border-top: 10px solid #fff;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    left: -10px;
    top: -11px;
}

.customTip .react-tooltip-lite-down-arrow::before {
    border-bottom: 10px solid #fff;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    left: -10px;
    bottom: -11px;
}

.customTip .react-tooltip-lite-right-arrow::before {
    border-right: 10px solid #ccc;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    right: -11px;
    top: -10px;
}

.customTip .react-tooltip-lite-left-arrow::before {
    border-left: 10px solid #ccc;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    left: -11px;
    top: -10px;
}

.customTip.l10 .react-tooltip-lite-arrow {
    margin-left: 10px;
}

.customTip.l-10 .react-tooltip-lite-arrow {
    margin-left: -10px;
}

.rc-menu__item {
    font-size: 16px;
    color: #757575;
    padding: 0.375rem 10px !important;
}

.rc-menu__item--hover {
    color: #000;
}

.cMenu1 .rc-menu {
    border: 1px solid #dedede !important;
    box-shadow: none !important;
    margin: 14px 0 0 -5rem !important;
}

.cMenu1 .rc-menu-container {
    transform: translateX(-50%);
}

.cMenu1 .rc-menu:after, .cMenu1 .rc-menu:before {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.cMenu1 .rc-menu:after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #ffffff;
    border-width: 10px;
    margin-left: -10px;
}

.cMenu1 .rc-menu:before {
    border-color: rgba(222, 222, 222, 0);
    border-bottom-color: #dedede;
    border-width: 11px;
    margin-left: -11px;
}

.border-top-real {
    border-top: 1px solid #e5e5e5;
}

.border-top {
    -webkit-box-shadow: inset 0 1px 0 0 #e5e5e5;
    box-shadow: inset 0 1px 0 0 #e5e5e5;
}

.border-right {
    -webkit-box-shadow: inset -1px 0 0 0 #e5e5e5;
    box-shadow: inset -1px 0 0 0 #e5e5e5;
}

.border-bottom {
    -webkit-box-shadow: inset 0 -1px 0 0 #e5e5e5;
    box-shadow: inset 0 -1px 0 0 #e5e5e5;
}

.border-left {
    -webkit-box-shadow: inset 1px 0 0 0 #e5e5e5;
    box-shadow: inset 1px 0 0 0 #e5e5e5;
}

hr {
    height: 1px;
    border: none;
    margin: 0;
    -webkit-box-shadow: inset 0 1px 0 0 #e5e5e5;
    box-shadow: inset 0 1px 0 0 #e5e5e5;
}

.d-sm-flx-new {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.flx-as-sm-c {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    align-items: center;
}

.flx-jc-sm-c {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.tooltipClass {
    color: #222 !important;
    background-color: #666 !important;
}

.tooltipClass.place-top:after {
    border-top-color: #666 !important;
    border-top-style: solid !important;
    border-top-width: 6px !important;
}

.tooltipClass.place-right:after {
    border-right-color: #666 !important;
    border-right-style: solid !important;
    border-top-width: 6px !important;
}

.tooltipClass.place-left:after {
    border-left-color: #666 !important;
    border-left-style: solid !important;
    border-top-width: 6px !important;
}

.tooltipPlacer.c-f-all {
    position: absolute;
    z-index: 1;
    left: 6px;
    top: 18px;
}