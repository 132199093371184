@import url('variables.css');

.ncss-container {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

[class*=g72] {
    position: static;
    font-size: 100%;
}

.ncss-radio-container {
    position: relative;
    text-align: left;
}

.border-warn {
    box-shadow: inset 0 0 0 1px var(--ncss-warn);
}

.text-color-accent {
    color: var(--ncss-accent);
}

.text-color-warn {
    color: var(--ncss-warn);
}

.text-color-info {
    color: var(--ncss-info);
}

.symbol-position {
    position: absolute;
    right: 150px;
}

.text-color-grey1 {
    color: #757575;
}

.text-color-black {
    color: #000000;
}

.text-color-blue1 {
    color: #4CA5D2;
}

.bg-warn {
    background-color: var(--ncss-warn);
}

.ncss-select-container .ncss-error-msg, .ncss-checkbox-container .ncss-error-msg {
    display: none;
    color: #fe0000;
    font-size: 12px;
    line-height: 2;
}

.ncss-select-container.error .ncss-error-msg, .ncss-checkbox-container .ncss-error-msg {
    display: block;
}

.ncss-input-container .ncss-input, .ncss-input-container .ncss-textarea, .ncss-textarea-container .ncss-input, .ncss-textarea-container .ncss-textarea {
    z-index: inherit;
}

.h1, h1 {
    font-size: 30px;
    line-height: 1.6;
}

.lh-h1 {
    line-height: 0.933333333333333;
}

.h2, h2 {
    font-size: 19px;
    line-height: 1.263157894736842;
}

.lh-h2 {
    line-height: 0.894736842105263;
}

.h3, h3 {
    font-size: 20px;
    line-height: 1.2;
}

.lh-h3 {
    line-height: 0.9;
}

.h4, h4 {
    font-size: 19px;
    line-height: 1.263157894736842;
}

.lh-h4 {
    line-height: 0.894736842105263;
}

.h5, h5 {
    font-size: 14px;
    line-height: 1.714285714285714;
}

.lh-h5 {
    line-height: 0.857142857142857;
}

.h6, h6 {
    font-size: 12px;
    line-height: 2;
}

.lh-h6 {
    line-height: 0.833333333333333;
}

@media only screen and (min-width: 641px) {
    .h1, h1 {
        font-size: 40px;
        line-height: 1.2;
    }

    .lh-h1 {
        line-height: 0.95;
    }

    .h2, h2 {
        font-size: 28px;
        line-height: 1.714285714285714;
    }

    .lh-h2 {
        line-height: 0.928571428571429;
    }

    .h3, h3 {
        font-size: 24px;
        line-height: 1;
    }

    .lh-h3 {
        line-height: 0.916666666666667;
    }

    .h4, h4 {
        font-size: 22px;
        line-height: 1.090909090909091;
    }

    .lh-h4 {
        line-height: 0.909090909090909;
    }

    .h5, h5 {
        font-size: 16px;
        line-height: 1.5;
    }

    .lh-h5 {
        line-height: 0.875;
    }

    .h6, h6 {
        font-size: 14px;
        line-height: 1.714285714285714;
    }

    .lh-h6 {
        line-height: 0.857142857142857;
    }
}

@media only screen and (min-width: 1025px) {
    .h1, h1 {
        font-size: 40px;
        line-height: 1.2;
    }

    .lh-h1 {
        line-height: 0.95;
    }

    .h2, h2 {
        font-size: 34px;
        line-height: 1.411764705882353;
    }

    .lh-h2 {
        line-height: 0.941176470588235;
    }

    .h3, h3 {
        font-size: 28px;
        line-height: 1.714285714285714;
    }

    .lh-h3 {
        line-height: 0.928571428571429;
    }

    .h4, h4 {
        font-size: 16px;
        line-height: 1.5;
    }

    .lh-h4 {
        line-height: 0.875;
    }

    .h5, h5 {
        font-size: 16px;
        line-height: 1.5;
    }

    .lh-h5 {
        line-height: 0.875;
    }

    .h6, h6 {
        font-size: 14px;
        line-height: 1.714285714285714;
    }

    .lh-h6 {
        line-height: 0.857142857142857;
    }
}

.ncss-flyout-btn, .ncss-flyout-options .ncss-option {
    display: inline-block;
    font-size: 14px;
    line-height: 1.714285714285714;
    text-decoration: none;
    cursor: pointer;
}

.ncss-flyout {
    display: inline-block;
    position: relative;
    z-index: 1;
}

.ncss-flyout:not([disabled]):not(.disabled):hover .ncss-flyout-options {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
}

.ncss-flyout.disabled, .ncss-flyout:disabled {
    opacity: 0.5;
}

.ncss-flyout.disabled .ncss-flyout-btn, .ncss-flyout:disabled .ncss-flyout-btn {
    cursor: default;
}

.ncss-flyout-btn {
    position: relative;
    z-index: 1;
    padding: 8px 40px 8px 20px;
}

.ncss-flyout-btn:after {
    font-family: nike-glyphs, sans-serif;
    content: '\E007';
    font-size: 10px;
    position: absolute;
    top: 50%;
    right: 16px;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
}

.ncss-flyout-span, .ncss-flyout-span-num {
    margin-left: 4px;
    color: #707070;
    vertical-align: top;
}

.ncss-flyout-span-num:before {
    content: '(';
}

.ncss-flyout-span-num:after {
    content: ')';
}

.ncss-flyout-options {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    left: 0;
    right: 0;
    background: #f1f1f1;
    max-height: 272px;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-box-shadow: inset -1px 0 0 0 #e5e5e5, inset 0 -1px 0 0 #e5e5e5, inset 1px 0 0 0 #e5e5e5;
    box-shadow: inset -1px 0 0 0 #e5e5e5, inset 0 -1px 0 0 #e5e5e5, inset 1px 0 0 0 #e5e5e5;
    -webkit-transform: translate(0, -20%);
    -ms-transform: translate(0, -20%);
    transform: translate(0, -20%);
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.ncss-flyout-options .ncss-option {
    width: 100%;
    color: #111;
    background: 0 0;
    -webkit-box-shadow: inset 0 1px 0 0 #e5e5e5;
    box-shadow: inset 0 1px 0 0 #e5e5e5;
}

.ncss-flyout-options .ncss-option:first-child {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.ncss-flyout-options .ncss-option:hover {
    background: #dfdfdf;
    color: #111;
}

.ncss-flyout-options .ncss-option.active, .ncss-flyout-options .ncss-option:active {
    background: #111;
    color: #fff;
    text-decoration: none;
    -webkit-box-shadow: inset 0 1px 0 0 #111;
    box-shadow: inset 0 1px 0 0 #111;
}

.typeahead-dropdown-container {
    overflow: visible;

    .typeahead-input {
        position: relative;
    }

    &.focus {
        .typeahead-options {
            opacity: 1;
            visibility: visible;
            transform: translate(0, 0);
        }
    }
}

.ncss-flyout-btn {
    border: 1px solid var(--ncss-grey-md);
    display: block;
}

.ncss-select-container {
    width: 100%;
    position: relative;
    display: block;
    text-align: left;
    overflow: visible;
}

.ncss-select-container.error {
    .Select-control, .is-focused:not(.is-open) > .Select-control {
        border-color: #fe0000;
    }
}

.ncss-select-container {
    .Select-value {
        display: inline-block;
        margin-left: 10px;
    }
}

.no-border-custom {
    border: none;
}

.flx-as-sm-fs {
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
}

.plr4-sm {
    padding-left: 4px;
    padding-right: 4px;
}

.p0-sm {
    padding: 0px;
}

.p2r-sm {
    padding: 2rem;
}

.mr-2-sm {
    margin-right: 1rem;
}

.ncss-checkbox-container.indeterminate .ncss-checkbox+.ncss-label:after {
    content: '\E62D';
}

.u-capitalize {
    text-transform: capitalize;
}