.rt-marker.rt-marker--now {
  color: #4ca5d2;
  border-color: #4ca5d2;
}

.ReactModal__Content {
  min-height: 400px;
  height: fit-content;
  margin-top: auto;
  margin-bottom: auto;
}

.rt-sidebar, .rt-layout__side {
  max-width: 80px !important;
}

.rt-layout__main {
  width: calc(100% - 80px) !important;
}

 
.line-up {
  padding-left: 21px;
  font-weight: 500;;
}
