.modal-container {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  background-color: #ffffff;
  margin: 0 auto;
  transform: translate(-50%, -50%);
  padding: 22px;
  width: 40%;
  min-width: 420px;
  border-radius: 10px;
  box-shadow: 0px 0px 13px #0000002b;
}

.modal-container-gbl {
  border: none !important;
  border-radius: 8px;
}

.modal-container-big {
  position: absolute;
  width: 90%;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 6;
}

.modal-close-btn {
  background: transparent;
  margin: 0px 17px 0 0;
  float: right;
  font-size: 24px;
  position: absolute;
  right: 0;
  z-index: 6;
}

.modal-right-container {
  position: fixed;
  top: 64px;
  right: 0;
  background-color: #efefef;
  margin: 0;
  padding: 10px 20px;
  width: 50%;
  height: calc(100vh - 64px);
  min-width: 1000px;

  @media screen and (max-width: 640px) {
    width: 90%;
    top: 40px;
  }

  @media screen and (max-width: 1200px) and (min-width: 640px) {
    width: 60%;
    top: 40px;
  }
}

.modal-container h5.u-bold {
  font-size: 24px;
  font-weight: 400;
}