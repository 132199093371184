@import url('variables.css');

.nav-header-wrapper {
  width: 100%;
  background: var(--nav-bg-color);
  color: #fff;
  height: 68px;
  box-shadow: var(--box-shadow-divider);
}


.nav-header-container{
  display: flex;
  width: 100%;
  a {
    color: #fff;
  }
}

.nav-header-title{
  flex: 1 1 auto;
  padding-top :8px;
  span {
    font-size: 2rem;
    @media screen and (min-width: 641px) {
      margin-left: 70px;
      position: absolute;
      font-size :22px;
      margin-top: 12px;
    }
  }
}


.nav-header-block {
  flex: 0 0 auto;

  &:last-child {
    margin-right: 0;
  }


  .nav-header-menu li {
    height: 68px;
    margin-right: 1rem;
    display: inline-block;



    a, span {
      padding: 0 .5rem;
      font-size: 1.3rem;
      font-weight: 700;
      vertical-align: middle;
      line-height: 68px;
      display: inline-block;
      height: 68px;

      &:active {
        border-bottom: 3px solid var(--ncss-accent);
        color: var(--ncss-accent);
      }
    }
  }
} 