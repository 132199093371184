@import url('variables.css');

.menu-container {
  display: flex;
  width: 100%;
  height: 100%;
}

.menu-lightbox {
  opacity: 0;
  visibility: hidden;
  background: rgba(0, 0, 0, 0);
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1001;
  transition: opacity 300ms ease;
}

.show-menu .menu-lightbox {
  opacity: 1;
  visibility: visible;
  background: rgba(0, 0, 0, 0.4);
}

.menu-logo {
  font-size: 22px;
  vertical-align: middle;
  margin-right: 1rem;
}

.menu-aside {
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  width: 300px;
  height: 100%;
  z-index: 10001;
  overflow: hidden;
  transform: translateX(-100%);
  transition: transform 300ms ease;
  background-color: #fff;

  .nav {
    width: 100%;
    height: auto;
    flex: 0 0 auto;
    overflow-y: auto;
  }

  .footer {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    .footer-spacer {
      display: block;
      flex: 1 1 0%;
    }

    span {
      margin: 0 auto;
      flex: 0 0 auto;
      display: flex;
    }
  }
}

.show-menu .menu-aside {
  transform: translateX(0);
  box-shadow: 0px 0px 12px 6px #0000005c;
  overflow: auto;
}

.viewport {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.menu-btn {
  position: fixed;
  top: 15px;
  left: 20px;
  font-size: 22px;
  z-index: 10002;
}

.profile-nav {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;

  .profile-nav-item {
    flex: 0 0 auto;
    vertical-align: middle;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    min-width: 0;

    span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      min-width: 0;
    }
  }

  &.profile-nav-item {
    flex: 1 1 auto;
  }

  .user-info {
    flex: 1 1 auto;
  }
}

.badge-notification {
  position: absolute;
  right: -8px;
  top: -8px;
  font-size: 1rem;
  background-color: red;
  color: ncss-white;
  display: inline;
  line-height: 0.8rem;
  padding: 3px 5px;
}

.menu-header {
  text-align: left;
  display: block;
  min-height: 64px;
  padding: 5px 0 1px 53px;
}

.menu-header.sidebar {
  padding: 5px 0 1px 15px;
}

.seperation-line {
  color: ncss-grey-light;
  margin: 15px 0px 15px 0px;
  border: 0;
  border-bottom: 1px solid #cecece;

  @media screen and (max-width: 728px) {
    margin: 10px 0px 10px 0px;
  }
}

.seperation-header {
  background-color: #efefef;
  margin: 10px 0 0 0;
  padding: 5px 10px;
}

.menu-aside .menu-item {
  display: block;
  margin: 0;
  padding: 0px 10px 0 10px;
  font-size: 16px;
  font-weight: 600;
}

.menu-aside .text-color-info.active {
  color: #0078b9;
}