.switch {
  display: inline-block;
  height: 28px;
  position: relative;
  width: 54px;
}

.switch input {
  display: none;
}

.switch .slider {
  background-color: #ccc;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.4s;
}

.switch .slider:before {
  background-color: #fff;
  bottom: 4px;
  content: '';
  height: 24px;
  left: 2px;
  position: absolute;
  transition: 0.4s;
  width: 24px;
  top: 2px;
}

input:checked + .slider {
  background-color: #66bb6a;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.switch .slider.round {
  border-radius: 34px;
}

.switch .slider.round:before {
  border-radius: 50%;
}

.switch.sm {
  display: inline-block;
  height: 14px;
  position: relative;
  width: 26px;
}

.switch.sm .slider.round {
  border-radius: 8px;
}

.switch.sm .slider:before {
  height: 10px;
  left: 2px;
  width: 10px;
}

.switch.sm input:checked + .slider:before {
  -webkit-transform: translateX(12px);
  transform: translateX(12px);
}

.switch.blue input:checked + .slider {
  background-color: #4CA5D2;
}