@import url("footer.css");
@import url("core.css");
@import url("navbar.css");
@import url("select.css");
@import url("ncss.css");
@import url("forms.css");
@import url("spinner.css");
@import url("variables.css");
@import url("markdown.css");
@import url("menu.css");
@import url("alertify.css");
@import url("tooltip.css");
@import url("header.css");
@import url("grid.css");
@import url("dashboard.css");
@import url("modal.css");
@import url("list.css");
@import url("datePicker.css");
@import url("switch.css");
@import url("create.css");
@import url("growthGrid.css");
@import url("lever.css");

html, body, #app-host {
  min-height: 100%;
  background-color: #efefef;
  font-family: 'Open Sans Condensed', sans-serif;
  font-family: HelveticaNeue, 'Nunito Sans', -apple-system, '.SFNSText-Regular', 'San Francisco', BlinkMacSystemFont, 'Inter', 'Segoe UI', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  letter-spacing: 0;
}

.footer {
  z-index: 5;
  bottom: 0;
  width: 100%; /* margin-top : 85px */

  @media screen and (max-width: 640px) {
    position: relative;
  }
}

.offline-section {
  padding: var(--card-padding);

  h2 {
    text-align: center;
    font-size: 4rem;
    text-transform: uppercase;
  }
}

.u-italic, em {
  font-style: normal;
  color: #0078b9;
}

.button-top-adjust .u-uppercase {
  float: right;
}

.main-heading {
  min-height: 48px;
  width: 100%;
  overflow: hidden;
  font-weight: 500;
  padding: 10px 10px 0px 10px;
  line-height: 30px;
}

.main-heading.p0 {
  padding: 0;
  margin: 0;
}

*:focus {
  outline: none;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .footer {
    position: sticky;
    bottom: 0;
    width: 100%;
  }
}

.not-required-select {
  top: 15px;
  right: 285px !important;
  width: 100px;
  position: fixed;
  z-index: 12;
}

.errorCenter {
  min-height: calc(100vh - 110px);
  text-align: center;
  padding-top: 0px;
}

input:disabled, select:disabled {
  background-color: #efefef9e !important;
}

.c-f-all input:disabled, .c-f-all select:disabled {
  background-color: #e5e5e5 !important;
}

.errorContentMax {
  max-width: 600px;
  text-align: left;
}

.errorContent {
  font-size: 16px;
  margin: 0 auto;
}

.bg-success-box {
  background: #f0f9ed;
  border: 1px solid #9aaf99;
  color: #2cab00;
  clear: both;
  padding: 10px;
  margin: 21px;
  border-radius: 10px;
  text-align: center;
}

.bg-error-box {
  background: rgb(253 249 248);
  color: #b93131;
  border: 1px solid #e1a99e;
  clear: both;
  padding: 10px;
  margin: 21px;
  border-radius: 10px;
  text-align: center;
}

.d-block-clear {
  display: block;
  clear: both;
}

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.tabContainer {
  display: block;
}

.tabButton {
  font-family: 'Open Sans Condensed', sans-serif;
  font-weight: bold;
  display: inline-block;
  padding: 5px 10px;
  margin: 0 5px 0 0;
  BORDER: 1PX SOLID #bdc3c7;
  BORDER-RADIUS: 3PX 3PX 0 0;
  width: auto;
  color: #000;
  min-width: 100px;
  background: #fff !important;
  text-align: center;
  text-transform: uppercase;
}

.tabButton.selected {
  background: #aeaaaa !important;
  border: 1px solid #aeaaaa;
  color: #fff;
  border-bottom: 0px solid #aeaaaa;
}

.adminPanel {
  margin: 0 0 15px 0;
  border: 1px solid #e5e5e5;
  padding: 15px;
  background: #fdfdfd;
  border-radius: 5px;
}

.adminPanel.noSide {
  padding: 15px 0;
}

.adminPanel h3 {
  margin: 0 0 15px 0;
  display: block;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: bold;
  color: #0078b9;
}

.gridBtn1 {
  margin: 1px;
  border: none;
  background: transparent;
  line-height: 12px;
  font-weight: 600;
  color: #0078b9;
  font-size: 12px;
}

.gridDelBtn {
  margin: 1px;
  border: none;
  background: transparent;
  line-height: 12px;
  font-weight: 600;
  color: #f00;
  font-size: 12px;
}

.ncss-error-msg {
  color: #d43f21;
  font-size: 12px;
  line-height: 2.333333333333333;
}

.app-menu-icon {
  background-position: center;
  display: inline-block;
  height: 18px;
  background-size: cover;
  width: 24px;
  background-repeat: no-repeat;
  margin-right: 6px;
}

.app-shoe-icon {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAQAAABKfvVzAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAAHdElNRQfkAhQHBSSggWaNAAABDUlEQVQ4y+3SzSoEcBQF8N8YQkpCTYpYsDDNUCYLKVJewUJ5Aiy8gBdQLGw8gGSnpAgLlLJQEikUJaWsZuQz4W9ByMckWck5m3Nv59zO4vKPnyDXrFXx7wfKPAhO1WQzbep7M80KgpVsgRE3Kp91tzNBcCWp6aM1qlmJeT3qnOgwZkA+yFMgrt6OItevgYh+7bokLCn/cC7tTq9Ot1odO3JohiHBIKpMCZ9yz9yLXmVZEEyqQNyw/S9iT7yISCsBlyaMW3OvWkpCQlKNgnclFyPu5bxZZGzYsu1QRkaOUrVSGjWI4UQL61krvHLfqHIiotqkJF27FJPnzIFCCcViMmKidqVNW3D+21/4d/EIDqJ14FkYB9AAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjAtMDItMjBUMDc6MDU6MzYrMDA6MDDaEQ8BAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIwLTAyLTIwVDA3OjA1OjM2KzAwOjAwq0y3vQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAAASUVORK5CYII=');
}

.apiErrorCode {
  display: block;
  clear: both;
  text-align: center;
}

.app-container.dev::before {
  content: 'DEVELOPMENT';
  position: absolute;
  top: 50px;
  font-size: 30px;
  text-transform: uppercase;
  font-weight: bold;
  color: #d2cfcf;
  right: 0;
}

.react-confirm-alert-body {
  font-family: -apple-system, BlinkMacSystemFont, 'Inter', Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
  box-shadow: 0 0px 10px #646464 !important;
  font-size: 18px;
  padding: 15px;
  color: #000;
  font-weight: 500;
}

.react-confirm-alert-button-group > button {
  background: #000;
  border: none;
  padding: 6px 18px;
  color: #fff;
  margin-right: 10px;
  border-radius: 25px;
  font-size: 14px;
  cursor: pointer;
}

.react-confirm-alert-overlay {
  background: rgba(140, 140, 140, 0.58);
}

.d-none {
  display: none;
}

.react-tabs {
  -webkit-tap-highlight-color: transparent;
  display: block;
  clear: both;
}

.react-tabs__tab-list {
  border-bottom: 1px solid #aaa;
  margin: 20px 0 0 0px;
  padding: 0;
  display: block;
  clear: both;
  padding-top: 13px;
}

.summary-toggle-parent {
  display: inline-table;
}

.toggle-wrapp {
  max-width: 122px;
  width: 100%;
  display: flex;
  align-items: right;
  padding-top: 12px;
  margin: 0 auto;
}

.toggle {
  width: 80px;
  height: 30px;
  position: relative;
  border: 2px solid #000000;
  margin: 0 20px;
  border-radius: 25px;
  cursor: pointer;
  background-color: #000000;
  transition: 300ms all;
  color: #fff;
  margin-top: -12px;
}

.toggle i {
  position: absolute;
  top: 4px;
  left: 7px;
  transition: 300ms all linear;
  font-size: 20px;
  color: #ffffff;
}

.active.toggle i {
  left: 50px;
}

.menuOpt1 {
  float: left;
  min-height: 55px;
}

.notification-dot-space {
  display: inline-block;
  min-width: 30px;
}

.menuOpt1Item {
  font-size: 16px;
  font-weight: 500;
  display: inline-block;
  padding: 1px 10px 1px 10px;
  color: #999999;
  line-height: 23px;
  letter-spacing: 0.333333px;
  min-height: 55px;
  cursor: pointer;
}

.menuOpt1Item.active span.text {
  border-bottom: 3px #000 solid;
  padding-bottom: 2px;
}

.menuOpt1Item.active {
  color: #000;
}

.notification-count-top {
  display: block;
  font-family: system-ui, -apple-system, system-ui, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 10px;
  height: 17.5px;
  line-height: 17.5px;
  text-align: center;
  padding: 0 5px;
  min-width: 17.5px;
  position: absolute;
  right: -15px;
  top: -10px;
  background: white;
  color: #2962FF;
  -webkit-border-radius: 12.5px;
  -moz-border-radius: 12.5px;
  -ms-border-radius: 12.5px;
  border-radius: 12.5px;
}

.notification-count-side {
  display: inline-block;
  font-size: 10px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  padding: 0 5px;
  min-width: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  border-radius: 20px;
  float: left;
  margin: 6px 0 0 2px;
}

.bg-primary {
  background: #4ca5d2;
  color: #fff;
}

.bg-amber {
  background: #FA5400;
  color: #fff;
}

.bg-grey {
  background: #999999;
  color: #fff;
}

.notification-no-count-side {
  display: inline-block;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  padding: 0 4px;
  width: 10px;
  height: 10px;
  color: #fff;
  border-radius: 10px;
  float: left;
  border: 1px solid #fff;
}

.notification-no-count-small {
  display: inline-block;
  font-size: 10px;
  height: 10px;
  line-height: 10px;
  text-align: center;
  padding: 0px;
  width: 10px;
  color: #fff;
  border-radius: 10px;
  float: left;
  border: 1px solid #fff;
}

.menuOpt2Item {
  font-size: 14px;
  font-weight: normal;
  color: #666;
  display: inline-flex;
  padding: 0px 15px 0px 14px;
  white-space: nowrap;
  text-align: right;
  letter-spacing: 0.3px;
  min-height: 50px;
  border-right: 1px solid #cfcfcf;
}

.menuOpt2Item span.text {
  line-height: 20px;
  display: block;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  color: #000;
}

.menuOpt2Item span.text.active {
  border-bottom: 2px #000 solid;
  color: #000;
}

.menuOpt2Item .alert {
  white-space: nowrap;
  font-size: 80%;
}

.menuOpt2Item.box {
  border: 1px solid #c5c5c5;
  background-color: #e7e7e7;
  border-radius: 10px;
  padding: 5px;
  text-align: center;
  box-shadow: inset 0px 1px 22px #d3d3d3;
  margin-left: -1px;
}

.menuOpt2Item .search-new-container {
  background: none !important;
  border: none !important;
  text-align: left;
  padding: 0;
}

.menuOpt2Item .search-new-container .floatHead {
  background-color: transparent;
  display: none;
}

.menuOpt2Item .newSearchCol-2 {
  padding-top: 5px;
  padding-left: 1px;
}

.create-icon-svg {
  width: 15px;
  height: 15px;
}

.create-icon-svg-note {
  margin-top: -16px;
  z-index: 2;
  position: absolute;
  width: 22px;
  margin-left: 9px;
}

.p-r {
  position: relative;
}

.p-a {
  position: absolute;
}

.pageNotifyContainer {
  position: relative;
  width: 100%;
  clear: both;
}

.paginateNotify {
  clear: both;
  display: none;
  width: 200px;
  left: calc(50vw - 100px);
  text-align: center;
  margin: 0 auto;
  margin-top: -51px;
  position: absolute;
  opacity: 0;
  -webkit-transition: all 1s ease-in-out;
  -moz-transition: all 1s ease-in-out;
  -ms-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
}

.paginateNotify.in {
  display: block;
  opacity: 0.8;
}

.paginateNotify.in:hover {
  opacity: 1;
}

.paginateNotifyBtn {
  padding: 5px 18px;
  border-radius: 20px;
  background: #111;
  color: #fff;
  font-size: 12px;
  min-height: 36px;
  min-width: 100px;
}

.row-align-top {
  vertical-align: top;
}

.searchbox {
  display: block;
  min-height: 30px;
  margin-bottom: 5px;
}

.searchbox input {
  width: calc(100% - 25px);
  float: left;
  display: block;
  padding: 5px 0;
}

.searchboxIcon {
  float: right;
}

.searchboxIcon svg {
  width: 14px;
  height: 14px;
}

.customScrollBar {
  width: 100%;
  overflow: auto;
  scrollbar-color: #cccccc white;
  scrollbar-width: thin;
}

.customScrollBar::-webkit-scrollbar-track {
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.1);
}

.customScrollBar::-webkit-scrollbar {
  width: 6px;
  border-radius: 15px;
  background-color: #fff;
}

.customScrollBar::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 20px;
}

.customBottom {
  bottom: 0;
  padding: 10px;
  font-family: monospace;
  font-size: 12px;
}

.customBottom th {
  padding-right: 15px;
  font-weight: 600;
}

.customBottom a {
  color: #f00;
}

.DayPicker-NavButton {
  right: 0 !important;
}

.DayPicker-NavBar {
  float: right;
  width: 10px;
  display: block;
  height: 10px;
}

.pushtooltip>div>div {
  margin-left: -30px;
  margin-top: -10px;
}

.noShowUI {
  font-size: 9px;
  opacity: 0.6;
  display: none;
}

.toolTipElm {
  background: #fff;
  border: 1px solid #e6e6e6;
  color: #000;
  font-size: 10px;
  padding: 1px 5px;
}

.Toastify__toast {
  padding: 10px 22px !important;
  border-radius: 40px !important;
  text-align: center;
  font-family: 'Helvetica Neue' !important;
  font-weight: bold !important;
  font-size: 14px;
}

.Toastify__toast-container {
  width: auto !important;
  min-width: 400px !important;
  max-width: 650px !important;
}

.Toastify__toast--success {
  background: #128a09 !important;
  color: #000 !important;
}

.Toastify__toast--error {
  background: #d43f21 !important;
  color: #000 !important;
}

.Toastify__toast--warning {
  background: #FBBE3B !important;
  color: #000 !important;
}

.Toastify__close-button {
  font-size: 20px !important;
  font-weight: normal !important;
}

.Toastify__toast-body {
  margin: auto 10px !important;
  font-weight: 500 !important;
}

.Toastify__toast-container--top-center {
  margin-left: 0px !important;
  transform: translateX(-50%) !important;
}

.score-card-count {
  min-width: 45px;
}

.open-sans-c {
  font-family: 'Open Sans Condensed', sans-serif;
}

.open-sans-c.u-bold {
  font-weight: 600 !important;
}

.capvis-page-container {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 3px #d9d9d9;
  padding: 15px 1px;
}

.capvis-page-container.sm {
  min-height: 20vh;
}

div#myQuarterGrid {
  clear: both;
}

.error-page-container {
  min-height: 100vh;
  padding: 60px 20px;
}

.react-tabs__tab-list .menuOpt1Item {
  cursor: pointer;
}

.menuOpt1Item.react-tabs__tab--selected {
  background: transparent;
  border-color: #aaa;
  color: black;
  border-radius: 5px 5px 0 0;
}

.menuOpt1Item.react-tabs__tab--selected span.text {
  border-bottom: 3px #000 solid;
  padding-bottom: 2px;
}

.react-tabs__tab-list {
  border-bottom: none;
  margin: 0;
}