.adminPanel .validation-header {
    font-size: 18px;
    line-height: 22px;
    color: #000000;
    text-transform: capitalize;
    font-weight: 500;
}

.adminPanel .validation-header-description {
    padding: 0px 9px;
    font-size: 14px;
    line-height: 20px;
}

.adminPanel .thresholdCol {
    width: 100px;
    margin-right: 10px;
}

.adminPanel .validation-label {
    font-size: 14px;
    line-height: 20px;
    color: #6A6A6A;
    margin-top: 10px;
    text-transform: capitalize;
    font-weight: 500;
}

.adminPanel .validation-label-padding {
    padding: 0px 9px;
}

.adminPanel .validation-margin {
    margin-top: 25px;
}

.adminPanel .ag-theme-balham {
    border-top: 1px solid #f3f3f3;
}

.iconWrapper {
    position: relative;
}

.iconWrapper:before {
    display: flex;
    position: absolute;
    left: 3px;
    top: -2px;
    height: 100%;
    padding: 4px 5px;
    content: '+';
    color: #757575;
    font-size: 14px;
    align-items: center;
    z-index: 1;
}

.decrement:before {
    content: '-';
}

.iconWrapper:after {
    display: flex;
    position: absolute;
    right: 3px;
    top: 0px;
    height: 100%;
    padding: 4px 5px;
    content: '%';
    color: #757575;
    font-size: 13px;
    align-items: center;
}

.validation-text-field {
    outline: none;
    border: 1px #ccc solid;
    border-radius: 4px;
    min-height: 29px;
    line-height: 2.3;
    padding: 0px 18px;
    position: absolute;
    color: #111;
    width: 100%;
    min-width: 100px;
    font-size: 12px;
    position: relative;
}

.adminPanel.noborder {
    border: none;
}

.adminPanel .catSelCol {
    width: 300px;
    margin-right: 10px;
}

.gblAdminListInput {
    min-width: 345px;
}

.gblAdminListText {
    min-width: 345px;
    display: inline-block;
}

.rst__moveHandle, .rst__loadingHandle {
    box-shadow: none !important;
    background-color: #fff !important;
    border: solid #c9c9c9 1px !important;
    border-radius: 20px 0 0 20px !important;
    background-size: 20px 20px !important;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaWQ9Imljb24iIHZpZXdCb3g9IjAgMCAzMiAzMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6bm9uZTt9PC9zdHlsZT48L2RlZnM+PHRpdGxlLz48cG9seWdvbiBwb2ludHM9IjQgMjAgMTUgMjAgMTUgMjYuMTcgMTIuNDEgMjMuNTkgMTEgMjUgMTYgMzAgMjEgMjUgMTkuNTkgMjMuNTkgMTcgMjYuMTcgMTcgMjAgMjggMjAgMjggMTggNCAxOCA0IDIwIi8+PHBvbHlnb24gcG9pbnRzPSIxMSA3IDEyLjQxIDguNDEgMTUgNS44MyAxNSAxMiA0IDEyIDQgMTQgMjggMTQgMjggMTIgMTcgMTIgMTcgNS44MyAxOS41OSA4LjQxIDIxIDcgMTYgMiAxMSA3Ii8+PHJlY3QgY2xhc3M9ImNscy0xIiBkYXRhLW5hbWU9IiZsdDtUcmFuc3BhcmVudCBSZWN0YW5nbGUmZ3Q7IiBoZWlnaHQ9IjMyIiBpZD0iX1RyYW5zcGFyZW50X1JlY3RhbmdsZV8iIHdpZHRoPSIzMiIvPjwvc3ZnPg==') !important;
    background-repeat: no-repeat !important;
    background-position: 12px 10px !important;
}

.rst__rowContents {
    box-shadow: none !important;
    border-radius: 0 20px 20px 0 !important;
}

.rst__rowLabel {
    padding-right: 5px !important;
}

.rst__rowContents {
    padding-right: 0 !important;
}

.rst__toolbarButton button {
    background: #f9f9f9;
    color: #838383;
    padding: 7px 18px 7px 10px;
    border-left: 1px solid #dedede;
    text-transform: uppercase;
    border-radius: 0 20px 20px 0 !important;
}

.rst__toolbarButton button.addDimensionButton {
    min-width: 119px;
}

.rst__rowTitle {
    font-weight: normal !important;
}

.rst__rowTitle .switchContainer {
    padding-top: 5px !important;
}

.rst__rowWrapper {
    padding: 0px 10px 10px 0 !important;
}

.rst__lineHalfHorizontalRight::before, .rst__lineFullVertical::after, .rst__lineHalfVerticalTop::after, .rst__lineHalfVerticalBottom::after {
    background-color: #cccccc !important;
}

.rst__lineChildren::after {
    background-color: #cccccc !important;
}

.switchContainer.adminToggleText {
    font-weight: normal;
    line-height: 0px;
    display: inline-block;
    padding: 0 0 14px 10px;
    color: #838383;
    min-width: 71px;
}

.ag-header-cell-text {
    line-height: 18px !important;
}