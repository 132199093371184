:root{
  --background-color: rgb(241, 241, 241);
  --ncss-accent: #fa5400;
  --ncss-success: #48BD1F;
  --ncss-error: #FE0000;
  --ncss-warn: rgb(238, 180, 80);
  --ncss-info: #0078b9;
  --ncss-black: #111;
  --ncss-white: #fff;
  --ncss-offwhite: #F7F7F7;
  --ncss-grey: #8D8D8D;
  --ncss-orange: rgb(255, 90, 0);
  --ncss-grey-md: #DDDDDD;
  --ncss-grey-light: #E5E5E5;
  --min-break-md: 640px;
  --min-break-lg: 1024px;
  --min-break-xl: 1280px;
  --min-break-xxl: 1440px;
  --ncss-link-color: #3498db;
  --nav-bg-color: var(--ncss-black);
  --box-shadow-divider: 0 0 5px rgba(0, 0, 0, 0.5);
  --card-padding: 1rem;
} 