@import url('variables.css');

.footer-container {
    color: var(--ncss-white);
    padding: 10px 0;
    color: ncss-white;
    display: block;
}

.footer-copyright {
    display: block;
    float: right;
}

.footer-margin-adjust {
    margin-top: 0px;
    margin-bottom: 0px;
}

.vertical-line {
    border-left: 3px solid var(--ncss-white);
    margin: 15px;
}

.footer-hr {
    border-color: var(--ncss-grey);
}

.page-align-items {
    flex: 1 0 auto;
    min-height: calc(100vh - 144px);
    margin: 64px 0 0px 0;

    @media screen and (max-width: 990px) {
        margin: 64px 0px 60px 0px;
    }

    @media screen and (max-width: 640px) {
        margin: 64px 0px 0px 0px;
    }
}

.page-align-items.old {
    margin: 44px 30px 60px 30px;
}

.page-align-fixed-items {
    overflow: hidden;
    padding: 0px 0px 0px 0px !important;
}
