@import url('variables.css');

html {
  background-color: var(--background-color);
}

body {
  background-color: var(--background-color);
}

.link {
  text-decoration: underline;
}

.u-va-tb {
  vertical-align: text-bottom;
}

.hidden {
  display: none;
}

.invisible {
  opacity: 0;
}

.center {
  margin-left: auto;
  margin-right: auto;
}

.relative-container {
  position: relative;
}

ul.list-unstyled {
  list-style-type: none;
}

ul.horizontal li {
  display: inline-block;
  list-style-type: none;
}

.page-header {
  min-height: 60px;
}

.page-menu-header {
  min-height: 93px;
  padding: 10px 0 0 0;
}

.page-header {
  h1.page-title-has-back {
    position: relative;

    .page-title-back {
      position: absolute;
      bottom: -1.5rem;
      left: 0;
      font-size: 0.8rem;
      color: ncss-accent;
    }
  }

  .ncss-flyout-btn {
    padding: 2px 40px 2px 20px;
  }
}

.page-sub-header {
  h1, h2 {
    padding-top: 0.5rem;
    padding-bottom: 1rem;
  }
}

.section-header {
  .section-header-item {
    display: inline-block;
    vertical-align: middle;
  }
}

.u-rotate-right {
  transform: rotate(90deg);
}

.u-rotate-left {
  transform: rotate(270deg);
}

.ncss-dl {
  dt {
    font-weight: 600;
  }

  dd {
    padding: 0 0 0.5rem 0;
  }
}

.ncss-dl-inline {
  dt, dd {
    display: inline;
    margin: 0;
  }

  dt {
    font-weight: 600;
  }

  dd {
    padding: 0 0 0.5rem 0.5rem;
  }

  dd:after {
    content: '\A';
    white-space: pre;
  }
}

.selectable-highlight {
  padding: 0.5rem 1rem;
  background-color: var(--ncss-white);
  border: 1px solid var(--ncss-success);
  display: inline-block;
  font-weight: bold;
  color: var(--ncss-grey);
  overflow: auto;
}

.u-nowrap {
  white-space: nowrap;
}

.flex-container {
  display: flex;
  align-items: flex-end;

  &.start {
    align-items: start;
  }

  &.center {
    align-items: center;
    justify-content: center;
  }
}

.flex-fill {
  flex: 1 0 auto;
}

.flex-fluid {
  flex: 1 1 auto;
}

.flex-static {
  flex: 0 0 auto;
}

.u-half-width {
  width: 50%;
}

.u-half-width-md {
  @media only screen and (min-width: var(--min-break-md)) {
    width: 50%;
  }
}

.u-half-width-lg {
  @media only screen and (min-width: var(--min-break-lg)) {
    width: 50%;
  }
}

.u-half-width-xl {
  @media only screen and (min-width: var(--min-break-xl)) {
    width: 50%;
  }

  .u-half-width-xxl {
    @media only screen and (min-width: var(--min-break-xxl)) {
      width: 50%;
    }
  }
}

.card-container {
  padding: var(--card-padding);
  background-color: var(--ncss-white);
  box-shadow: var(--box-shadow-divider);
}

.circle {
  border-radius: 50%;
  padding: 1px 3px;
}

.page-header, h1.page-title-has-back {
  position: relative;
  padding: 10px 0 0 0;
}

.page-header.old, h1.page-title-has-back.old {
  position: relative;
  margin: 1rem;
}

.rounded {
  border-radius: 25%;
}

.align-center {
  text-align: center;
}

.big-button {
  font-size: 30px;
  background-color: var(--ncss-white);
  border: 1px solid var(--ncss-grey);
}

.big-button:focus {
  outline: 0;
}

.big-button-selected {
  font-size: 30px;
  background-color: var(--ncss-accent);
  border: 1px solid var(--ncss-accent);
}

.big-button-selected:focus {
  outline: 0;
}

.icon-button-margin {
  margin: 5px !important;
}

.button-top-adjust {
  position: relative;
}

.sub-heading {
  font-size: 25px;
}

.sub-header {
  font-size: 16px;
  color: #8d8d8d;
}

.text-base {
  font-family: inherit;
}

table.border, table.border td {
  border: 1px solid #dedede;
  padding: 5px;
}
