@import url('variables.css');

.dashboard {
   .status-area {
      vertical-align: top;
      padding: 2rem 0 0 0;
   }

   .status-box {
      padding: 0.5rem 0;
   }

   .chart-area {
      border-left: 1px solid var(--ncss-grey);
      padding: 0 5rem;
   }

   .p0-top-sm {
      padding: 0 1px 1px 1px;
   }

   .m0-sm {
      margin: 0;
   }

   .lab {
      margin-left: -36px;
      margin-right: -34px;
   }
}

.dashboard-button {
   display: inline-block;
   text-transform: capitalize;
   padding: 6px 15px;
   margin: 0px 5px;
   border-radius: 25px;

   @media screen and (max-width: 640px) {
      display: inline-block;
      width: 21%;
      margin: 0 2%;
      padding: 5px 10px;
   }
}

.comment-container {
   height: 100%;
   padding: 0;
}

.comment-section {
   height: calc(100vh - 280px);
   overflow: auto;
   width: 100%;
}

.lever-comment-section {
   overflow: auto;
   width: 100%;
}

.log-section {
   height: calc(100vh - 125);
   overflow: auto;
   width: 100%;
}

.lever-log-section {
   height: calc(100vh - 235px);
   overflow: auto;
   width: 100%;
}

.comment-box {
   width: 100%;
   background: #ffffff;
   border-radius: 5px;
   padding: 15px;
   position: relative;
   margin-bottom: 12px;
   display: block;
   color: #757575;
   box-shadow: 0px 0px 3px #d9d9d9;
}

.comment-head {
   overflow: hidden;
}

.comment-content {
   border-top: 1px solid #E5E5E5;
   padding: 2px 10px;
   font-size: 15px;
}

.comment-foot {
   background: #E5E5E5;
   padding: 0px;
   overflow: hidden;
   border-radius: 0 0 4px 4px;
   display: block;
   min-height: 30px;
   padding-left: 10px;
}

.textareaComment {
   min-height: 60px;
   border: 1px solid #E5E5E5;
   background: #fefefe;
   box-shadow: none;
   display: block;
   width: 100%;
}

.comment-add-button {
   display: block;
   float: right;
   background-color: #000;
   border: none;
   color: #fff;
   border-radius: 0 0 5px 0;
   padding: 5px 10px;
}

.score-card-divider {
   border-left: 1px solid #dedede;

   @media screen and (max-width: 640px) {
      border-top: 1px solid #dedede;
   }
}

.score-card-divider:nth-child(1), .score-card-divider:nth-child(2) {
   border-top: none !important;
}

.score-card-divider:nth-child(1) {
   border-left: none !important;
}

.score-card-divider:nth-child(odd) {
   @media screen and (max-width: 640px) {
      border-right: 1px solid #dedede;
      border-left: none;
   }

   @media screen and (min-width: 640px) {
      border-left: 1px solid #dedede;
   }
}

.border-solid-light-grey {
   border: 1px solid #efefef;
}

.ncss-btn-primary-dark {
   padding: 5px 18px;
   border-radius: 20px;
   background: #111;
   color: #fff;
   min-height: 36px;
   font-weight: 500;
}

.ncss-btn-accent-dark {
   padding: 5px 24px;
   border-radius: 20px;
   background: #fa5400;
   color: #fff;
   margin-right: 5px;
   font-weight: 500;
}

.ncss-btn-secondary {
   padding: 5px 20px;
   border-radius: 20px;
   background: #fff;
   color: #111;
   box-shadow: inset 0 1px 0 0 #ccc, inset -1px 0 0 0 #ccc, inset 0 -1px 0 0 #ccc, inset 1px 0 0 0 #ccc;
   font-size: 12px;
   font-weight: 500;
   min-height: 36px;
}

.ncss-btn-secondary-lite {
   padding: 5px 20px;
   border-radius: 20px;
   background: #fff;
   color: #666;
   border: 1px solid #dedede;
   font-size: 12px;
   font-weight: 500;
}

.ncss-btn-sm {
   padding: 5px 20px;
   border-radius: 20px;
   font-size: 12px;
   min-height: 30px;
}

.ncss-btn-sm-sp {
   padding: 5px 10px;
   border-radius: 20px;
   font-size: 12px;
   min-height: 30px;
}

.ncss-btn-large {
   padding: 5px 20px;
   border-radius: 30px;
   font-size: 16px;
   font-weight: bold;
   min-height: 40px;
}

.text-color-error {
   color: #fa5400;
   font-weight: normal;
}

.numberList ol {
   list-style: decimal;
   max-height: 60vh;
   overflow-y: scroll;
   padding: 0 0 0 30px;
   color: #000000;
   font-size: smaller;
}

select:disabled {
   background-color: rgb(235, 235, 228);
}

.rbt-input-hint-container input, .rbt-input-multi {
   position: relative;
   min-height: 1px;
   font-size: 14px;
   width: 100%;
   display: block;
   line-height: 1.714285714285714;
   padding-left: 8px;
   padding-right: 8px;
   -webkit-box-shadow: inset 0 1px 0 0 #111, inset -1px 0 0 0 #111, inset 0 -1px 0 0 #111, inset 1px 0 0 0 #111;
   box-shadow: inset 0 1px 0 0 #111, inset -1px 0 0 0 #111, inset 0 -1px 0 0 #111, inset 1px 0 0 0 #111;
}

.rbt-input-multi {
   border-radius: 2px;
   padding: 3px 8px;
}

.rbt-input-multi.focus {
   box-shadow: none !important;
   border: 1px solid #000;
}

.rbt-menu {
   float: left;
   z-index: 5;
   min-width: 10rem;
   padding: 0.5rem 0;
   margin: 0.125rem 0 0;
   font-size: 1rem;
   color: #212529;
   text-align: left;
   list-style: none;
   background-color: #fff;
   background-clip: padding-box;
   border: 1px solid rgba(0, 0, 0, 0.15);
   border-radius: 0.25rem;
   top: 0px;
   left: 0px;
   transform: translate3d(0px, 38px, 0px);
}

.rbt-menu>li a {
   display: block;
   width: 100%;
   padding: 0.25rem 1.5rem;
   clear: both;
   font-weight: 400;
   color: #212529;
   text-align: inherit;
   white-space: nowrap;
   background-color: transparent;
   border: 0;
   overflow: hidden;
   text-overflow: ellipsis;
}

.myGridUploadOnly {
   display: none;
   height: 1px !important;
   overflow: hidden;
}

.ncss-list-ol {
   padding-left: 16px;
   list-style-type: decimal;
}

.rbt-token {
   background-color: #cccccc;
   color: #000000;
   margin: 0 3px 3px 0;
   padding: 2px 16px 2px 2px;
   font-weight: 500;
}

.rbt-token .rbt-token-remove-button {
   font-weight: bold;
   padding: 5px 4px 2px 0;
   background: transparent;
   font-family: fantasy;
}

.commentNoDataMsg {
   padding: 47% 0 0 0;
   text-align: center;
}

.lever-commentNoDataMsg {
   height: calc(100vh - 350px);
   padding: 47% 0 0 0;
   text-align: center;
}

span.time-comment {
   float: right;
}

.validation-alert-text-margin {
   margin: 12px 0px !important;
}

.validation-alert-box {
   border-radius: 8px;
   width: 440px;
}

.validation-alert-button-text {
   font-size: 16px;
   font-weight: 100;
}

.partialSaveSpinner {
   position: absolute;
   width: 90vw;
   height: 75vh;
   z-index: 100;
}

.gbd-validation-modal-margin-bottom {
   margin-bottom: 5px;
}

.search-new-container {
   min-height: 50px;
   padding: 8px 5px 5px 10px;
   border-bottom: 1px solid #e9e9e9;
   background: rgba(250, 250, 250, 0.9);
}

.capacitySearchQuarterGrid .ag-cell.ag-cell-inline-editing, .capacitySearchWeekGrid .ag-cell.ag-cell-inline-editing {
   height: 55px;
}
