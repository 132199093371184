@import url('variables.css');

.progressBarCapCount {
    display: block;
    width: 30px;
    height: 20px;
    background: #ccc;
    margin: 1px 10px 0 0;
    border-radius: 3px;
}

.progressBarCapCount.bgCapcoler {
    background: #3A86FF;
}

.leverQuarterSummaryTable {
    width: 100%;
}

.leverQuarterSummaryTable th {
    text-align: left;
    color: #757575;
    font-size: 14px;
}

.leverQuarterSummaryTable td {
    text-align: right;
    font-size: 18px;
    font-weight: 600;
}

.targetCapacitytext {
    position: absolute;
    width: 102px;
    height: 20px;
    left: 0px;
    top: 0px; /* Subtitle 2 */
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px; /* identical to box height, or 143% */
    color: #757575;
}

input.leverInput, .DayPickerInput input {
    padding: 10px 10px;
    background: #fff;
    border: 1px solid #ccc;
    box-sizing: border-box;
    border-radius: 4px;
    width: 100%;
    min-width: 100px;
}

.lever-quarter-table .DayPickerInput .DayPickerInput-Overlay {
    top: -275px;
}

.Gbd-cost input.leverInput, .MaxQ-cost input.leverInput, .CapQ-cost input.leverInput {
    padding-left: 20px !important;
}

.Typeahead .rbt-menu {
    min-width: 23rem;
}

.csstypeAheadlever .rbt-menu {
    min-width: 23rem;
}

input.leverInput.capacityError, .capacityError .DayPickerInput input {
    border: 1px solid #f00 !important;
}

.Typeahead.capacityError {
    border: 1px solid #f00;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    --moz-appearance: textfield;
}

div.leverInput {
    width: 100%;
    min-width: 200px;
}

.errorBorderColor {
    border: 1px solid red;
}

.leverQuarterSummaryTablecolumnFirst {
    position: absolute;
    height: 20px; /* Subtitle 2 */
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-size: 14px;
    line-height: 20px; /* identical to box height, or 143% */
    /* Light BG / Grey 1 #757575 */
    color: #757575;
}

.leverQuarterSummaryTablecolumnSecond {
    height: 22px; /* Title 6 */
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-size: 18px;
    line-height: 22px; /* identical to box height, or 122% */
    color: #111111;
}

.QuarterSelectFiled {
    width: 20%;
}

.lever-page-container {
    background: #fff;
    box-shadow: 0px 0px 3px #d9d9d9;
    padding: 15px 1px;
}

.lever-page-container.m-20 {
    padding: 15px 20px;
    margin: 0 -20px;
}

.lever-tab-heading {
    font-size: 24px;
    font-weight: 500;
    line-height: 30px;
}

.lever-tab-count-f20 {
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
}

.lever-quarter-entry-container {
    background: #fff;
    border-radius: 10px;
    min-height: 300px;
    box-shadow: 0px 0px 3px #d9d9d9;
    padding: 0px;
    min-width: 1250px;
}

.lever-quarter-table-container {
    width: 100%;
    padding: 15px 0px 230px 0px;
}

.lever-quarter-table {
    width: 100%;
}

.lever-quarter-table th {
    padding: 20px 10px;
}

.lever-quarter-table tbody tr {
    border-top: 1px solid #CCCCCC;
    padding: 10px;
}

.lever-quarter-table tbody td {
    padding: 10px;
}

.lever-quarter-table td.text-cell {
    min-width: 180px;
}

.lever-quarter-table .rbt-input-hint-container input, .lever-quarter-table .rbt-input-multi {
    position: relative;
    min-height: 40px;
    font-size: 14px;
    width: 100%;
    display: block;
    line-height: 2.4;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: none;
}

.lever-quarter-table .rbt-input-hint-container input {
    padding-left: 30px !important;
}

.lever-quarter-table .rbt-input-multi {
    border-radius: 2px;
    padding: 3px 8px;
}

.lever-quarter-table .rbt-input-multi.focus {
    box-shadow: none !important;
    border: 1px solid #000;
}

.lever-quarter-table .rbt-menu {
    float: left;
    z-index: 100;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    top: 0px;
    left: 0px;
    transform: translate3d(0px, 38px, 0px);
}

.lever-quarter-table .rbt-menu>li a {
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    overflow: hidden;
    text-overflow: ellipsis;
}

.lever-quarter-table .rbt-token {
    background-color: #cccccc;
    color: #000000;
    margin: 0 3px 3px 0;
    padding: 2px 16px 2px 2px;
    font-weight: 500;
}

.lever-quarter-table .rbt-token .rbt-token-remove-button {
    font-weight: bold;
    padding: 5px 4px 2px 0;
    background: transparent;
    font-family: fantasy;
}

.lever-quarter-table .delete {
    display: block;
    float: left;
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGQ9Ik0xNC4yNSA3LjVWMTkuNSIgc3Ryb2tlPSIjMTExMTExIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIi8+DQo8cGF0aCBkPSJNOS43NSA3LjVWMTkuNSIgc3Ryb2tlPSIjMTExMTExIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIi8+DQo8cGF0aCBkPSJNNS4yNSA2VjE5LjVDNS4yNSAyMC43NCA2LjI2IDIxLjc1IDcuNSAyMS43NUgxNi41QzE3Ljc0IDIxLjc1IDE4Ljc1IDIwLjc0IDE4Ljc1IDE5LjVWNS4yNUgyMS41IiBzdHJva2U9IiMxMTExMTEiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiLz4NCjxwYXRoIGQ9Ik0xOC43NSA1LjI1SDIxIiBzdHJva2U9IiMxMTExMTEiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiLz4NCjxwYXRoIGQ9Ik05IDIuMjVIMTQuMjVDMTUuMDggMi4yNSAxNS43NSAyLjkyIDE1Ljc1IDMuNzVDMTUuNzUgNC41OCAxNS4wOCA1LjI1IDE0LjI1IDUuMjVIMyIgc3Ryb2tlPSIjMTExMTExIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIi8+DQo8L3N2Zz4NCg==');
}

.customHeaderMenuButton {
    float: left;
    margin: 0 0 0 3px;
}

.customHeaderLabel {
    float: left;
    margin: 0 0 0 3px;
    display: inline-block;
    padding-right: 30px;
    white-space: normal;
    width: inherit;
}

.customHeaderContent {
    display: block;
    min-height: 50px;
}

.customGridHeadActions {
    display: block;
    float: right;
    position: absolute;
    right: 0;
}

.customHeaderSubTitle {
    color: #6A6A6A;
    font-size: 12px;
    font-weight: normal;
}

.customSortDownLabel {
    float: left;
    margin: 0 0 0 3px;
}

.customSortUpLabel {
    float: left;
    margin: 0;
}

.customSortRemoveLabel {
    float: left;
    margin: 0 0 0 3px;
    font-size: 11px;
}

.active {
    color: #4c4c4c;
}

.quarterInput___control {
    border: none !important;
    background-color: transparent !important;
    min-width: 144px;
    box-shadow: none;
}

.quarterInput___single-value {
    font-size: 22px;
}

.quarterInput___value-container {
    padding: 2px 8px 2px 0px !important;
}

.quarterInput___control--is-focused {
    border: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
}

.lever-icon-space {
    display: inline-block;
    min-width: 52px;
    padding: 2px 15px;
    min-width: 55px;
}

.lever-icon {
    display: inline-block;
    width: 10px;
    height: 10px;
    color: #fff;
    border-radius: 10px;
    border: 1px solid #fff;
    background-color: #6A6A6A;
}

.lever-icon.bgBlue {
    background: #0861B5;
    color: #fff;
}

.lever-icon.bgYellow {
    background: #FBBE3B;
    color: #fff;
}

.lever-icon.bgGreen {
    background: #128A09;
    color: #fff;
}

r, .lever-icon.bgRed {
    background: #FF0000;
    color: #fff;
}

.lever-icon-space .lever-icon {
    float: left;
    margin-left: -2px;
}

.lever-icon-text p {
    padding-bottom: 2px;
}

.lever-icon-text .lever-icon {
    margin-right: 2px;
}

.quarter-tab-content {
    margin: 0 -1.5rem;
}

.lever-table-cell {
    max-width: 222px;
}

.lever-table-cell-indicator {
    width: 25px;
}

.lever-table-cell-indicator .lever-icon-space {
    display: block;
    min-width: 15px !important;
    padding: 7px 15px;
}

.lever-table-cell-capacity, .lever-table-cell-cost, .lever-table-cell-leverQty, .lever-table-cell-srcDecision {
    max-width: 165px;
}

.lever-table-cell-lever, .lever-table-cell-comment {
    min-width: 250px;
}

.csstypeAheadlever .rbt-menu {
    max-height: 230px !important;
    min-width: 23rem;
}

.leverToolTipInfo {
    position: absolute;
    display: block;
    z-index: 100;
    color: #898989;
}

.leverToolTipInfoContent {
    height: 20px;
    width: 21px;
    margin: 8px 0 0 5px;
    display: inline-block;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 7V8.5' stroke='%23CCCCCC' stroke-width='1.5' stroke-miterlimit='10'/%3E%3Cpath d='M12 10V17' stroke='%23CCCCCC' stroke-width='1.5' stroke-miterlimit='10'/%3E%3Cpath d='M12 19.25C16 19.25 19.25 16 19.25 12C19.25 8 16 4.75 12 4.75C8 4.75 4.75 8 4.75 12C4.75 16 8 19.25 12 19.25Z' stroke='%23CCCCCC' stroke-width='1.5' stroke-miterlimit='10'/%3E%3C/svg%3E%0A");
}

.costPrefix {
    position: absolute;
    display: block;
    z-index: 1;
    color: #898989;
    height: 20px;
    width: 21px;
    margin: 8px 0 0 5px;
    display: inline-block;
}

.partialQuarterWarning {
    display: block;
    text-align: center;
    padding: 2px;
    width: 100%;
    background-color: #cdcdcd;
    color: #858585;
    border-radius: 30px 30px 0 0;
    text-transform: uppercase;
}

.leverSummaryGrid .ag-header-cell.headGreyCloud {
    border-bottom: 5px solid #c4c4c4;
}

.leverSummaryGrid .ag-header-cell.headSapphireBlue {
    border-bottom: 5px solid #0372C3;
}

.leverSummaryGrid .editableCol {
    padding: 11px !important;
}

.leverSummaryGrid .editableCols::before {
    content: ' ';
    border: 1px solid #cfcfcf;
    display: block;
    left: 3px;
    position: absolute;
    border-radius: 9px;
    height: 42px;
    right: 1px;
}

.leverSummaryGrid .ag-cell.ag-cell-inline-editing {
    height: 55px;
    padding: 0;
}

#myQuarterGrid .ag-header-viewport {
    background-color: #fff;
}

.react-datepicker__day {
    color: #00bcd4;
}

.react-datepicker__day--disabled {
    color: #ccc;
}

.react-datepicker__close-icon::after {
    cursor: pointer;
    background-color: #666;
}

.react-datepicker__day--outside-month {
    color: transparent !important;
    pointer-events: none;
}

.react-datepicker__day.react-datepicker__day--keyboard-selected {
    background: none;
}

.react-datepicker__day--selected {
    position: relative;
    background-color: #4a90e2;
    color: #f0f8ff;
    border-radius: 1.3rem;
}

.lever-header {
    size: 36px;
    color: #6A6A6A;
    line-height: 40px;
    font-weight: 500;
}

.lever-header-breadcrumb {
    size: 36px;
    color: #111111;
    line-height: 40px;
    font-weight: 500;
}

.tag {
    background: #F5F5F5;
    border: 1px solid #F5F5F5;
    border-radius: 4px;
    padding: 3px;
    display: inline-block;
}

.lrcpDatePicker .react-datepicker__input-container input[type='text'] {
    width: 100%;
}

.lever-log-msg {
    text-align: center;
}