@import url('variables.css');

.alertify {
	position: fixed;
	background-color: rgba(0, 0, 0, 0.3);
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	z-index: 99999;

	&.hide {
		opacity: 0;
		pointer-events: none;
	}

	&, &.show {
		box-sizing: border-box;
		transition: all 0.33s cubic-bezier(0.25, 0.8, 0.25, 1);
	}

	&, * {
		box-sizing: border-box;
	}

	.dialog {
		padding: 12px;
	}

	.dialog, .alert {
		width: 100%;
		margin: 0 auto;
		position: relative;
		top: 50%;
		transform: translateY(-50%);

		> * {
			width: 400px;
			max-width: 95%;
			margin: 0 auto;
			text-align: center;
			padding: 12px;
			background: #fff;
			box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.14), 0 4px 5px 0 rgba(0, 0, 0, 0.098), 0 1px 10px 0 rgba(0, 0, 0, 0.084);
		}

		.msg {
			padding: 12px;
			margin-bottom: 12px;
			margin: 0;
			text-align: left;
		}

		input:not(.form-control) {
			margin-bottom: 15px;
			width: 100%;
			font-size: 100%;
			padding: 12px;

			&:focus {
				outline-offset: -2px;
			}
		}

		nav {
			text-align: right;

			button:not(.btn):not(.pure-button):not(.md-button):not(.mdl-button) {
				background: transparent;
				box-sizing: border-box;
				color: rgba(0, 0, 0, 0.87);
				position: relative;
				outline: 0;
				border: 0;
				display: inline-block;
				align-items: center;
				padding: 0 6px;
				margin: 6px 8px;
				line-height: 36px;
				min-height: 36px;
				white-space: nowrap;
				min-width: 88px;
				text-align: center;
				text-transform: uppercase;
				font-size: 14px;
				text-decoration: none;
				cursor: pointer;
				border: 1px solid transparent;
				border-radius: 2px;

				&:hover, &:active {
					background-color: rgba(0, 0, 0, 0.05);
				}

				&:focus {
					border: 1px solid rgba(0, 0, 0, 0.1);
				}
			}

			button.btn {
				margin: 6px 4px;
			}
		}
	}
}

.alertify-logs {
	position: fixed;
	z-index: 100;

	&.bottom, &:not(.top) {
		bottom: 16px;

	}
	&.left, &:not(.right) {
		left: 16px;

		.alertify-log {
			float: left;
		}
	}

	&.right {
		right: 16px;

		.alertify-log {
			float: right;
			transform: translateZ(0);

			&.alertify-log-enter {
				right: -110%;
			}

			&.alertify-log-enter-active {
				right: 0;
			}

			&.alertify-log-leave {
				right: -110%;
			}

			&.alertify-log-enter-active {
				right: 0;
			}
		}
	}

	&.top {
		top: 0;
	}

	.alertify-log {
		padding: 12px;
		color: #fff;
		box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
		border-radius: 1px;

		&, &.default {
			background: rgba(0, 0, 0, 0.8);
		}

		&.error {
			background: alpha(var(--ncss-error), 0.8);
		}

		&.success {
			background: alpha(var(--ncss-success), 0.9);
		}

		/* box-sizing: border-box;
		transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
		position: relative;
		clear: both;
		backface-visibility: hidden;
		perspective: 1000; */

		&.alertify-log-enter {
			max-height: 0;
			margin: 0;
			padding: 0;
			overflow: hidden;
			opacity: 0;
			pointer-events: none;
		}

		&.alertify-log-enter-active {
			margin-top: 12px;
			opacity: 1;
			max-height: 1000px;
			padding: 12px;
			pointer-events: auto;
		}

		&.alertify-log-leave {
			margin-top: 12px;
			opacity: 1;
			max-height: 1000px;
			padding: 12px;
			pointer-events: auto;
		}

		&.alertify-log-leave-active {
			max-height: 0;
			margin: 0;
			padding: 0;
			overflow: hidden;
			opacity: 0;
			pointer-events: none;
		}
	}
}

.notification-banners {
	position: absolute;
	width: 100%;
}

.notification-banner {
	background-color: var(--ncss-error);
	color: var(--ncss-white);
}

.notification {
	width: 100%;
	display: flex;

	span {
		flex: 1 0 auto;
	}

	button {
		flex: 0 0 auto;
		background-color: transparent;
		color: var(--ncss-white);
	}
}
