.react-calendar {
  width: 250px !important;
  max-width: 100% !important;
  z-index: 3000;
  position: relative !important;
  background-color: white !important;
  border: 1px solid black;
}

.react-calendar__navigation__label, .react-calendar__navigation__arrow {
  font-weight: 900;
}

.react-calendar__navigation {
  border-bottom: 1px solid #a0a096;
}

.react-calendar__tile {
  border-radius: 5px;
}

.react-calendar__tile--active {
  background: #3498db;
  font-weight: 900;
}

.react-date-picker__wrapper {
  display: flex;
  border: thin solid gray;
}

.react-date-picker__inputGroup {
  min-width: calc((4px * 3) + 0.54em * 8 + 0.217em * 2);
  flex-grow: 1;
  display: flex;
  padding: 0 2px;
  align-items: baseline;
  box-sizing: content-box;
}

.react-calendar__decade-view {
  padding: 0.3rem;
}

.react-calendar__tile {
  padding: 5px !important;
  margin-bottom: 0.3rem !important;
}

.react-date-picker__calendar--closed {
  display: none;
}

.react-date-picker__clear-button {
  display: none;
}

.react-date-picker__inputGroup__input {
  min-width: 0.54em;
  width: inherit !important;
  height: 100%;
  position: relative;
  padding: 0 1px;
  border: 0;
  background: none;
  font: inherit;
  box-sizing: content-box;
}

.DayPickerInput {
  display: block;
  width: inherit;
  max-width: 100%;
}

.DayPicker-Border input {
  border: 1px solid #000000;
  padding: 4px 7px;
  border-radius: 0;
  width: 100% !important;
}

.searchNew .DayPicker-Day--monday {
  color: #00bcd4;
}

.DayPicker-Day--disabled {
  color: #DCE0E0;
}

.DayPicker-Day {
  padding: 2px 0.5em;
}

input.flatpickr-input {
  width: 120px;
}

.custom-date-filter input.flatpickr-input {
  border: 1px solid #767676;
  margin-left: 4px;
}

.react-datepicker__input-container input[type='text'] {
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  min-height: 29px;
  padding: 4px 0px 4px 6px;
  font-size: 12px;
  min-width: 180px;
  background-repeat: no-repeat;
  background-position: 83% 50%;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='xMidYMid meet'%3E%3Cg%3E%3Ctitle%3Ebackground%3C/title%3E%3C/g%3E%3Cg%3E%3Ctitle%3ELayer 1%3C/title%3E%3Cpath stroke='null' id='svg_1' d='m12.55,9.575l-1.7,0l0,-1.7l1.7,0l0,1.7zm3.4,2.55l-1.7,0l0,-1.7l1.7,0l0,1.7zm0,2.55l-1.7,0l0,-1.7l1.7,0l0,1.7zm0,-5.1l-1.7,0l0,-1.7l1.7,0l0,1.7zm-10.20001,5.1l-1.7,0l0,-1.7l1.7,0l0,1.7zm12.75001,-11.05001l0,13.60001l-17.00001,0l0,-13.60001l2.55,0l0,-0.85l2.55,0l0,0.85l6.8,0l0,-0.85l2.55,0l0,0.85l2.55,0zm-0.85,2.55l-15.30001,0l0,10.20001l15.30001,0l0,-10.20001zm-11.90001,3.4l-1.7,0l0,-1.7l1.7,0l0,1.7zm3.4,5.1l-1.7,0l0,-1.7l1.7,0l0,1.7zm-3.4,-2.55l-1.7,0l0,-1.7l1.7,0l0,1.7zm6.8,2.55l-1.7,0l0,-1.7l1.7,0l0,1.7zm0,-2.55l-1.7,0l0,-1.7l1.7,0l0,1.7zm-3.4,-2.55l-1.7,0l0,-1.7l1.7,0l0,1.7zm0,2.55l-1.7,0l0,-1.7l1.7,0l0,1.7z'/%3E%3C/g%3E%3C/svg%3E");
}